
import * as React from 'react';
import { useRef, forwardRef, useImperativeHandle } from 'react';
import { constants, OBJECT_TABLEID_MAP, TABLEID_OBJECT_MAP } from '../../../services/constants/constants';
import { isValidParam, getArrayParam, getBooleanParam, getStringParam, getIntParam, getObjectParam, getFloatParam } from '../../../services/helper/parameterVerifier';
import { styles } from '../../../services/constants/styles';
import { getAppDrawer, getAppCustomDrawer } from '../../../services/actions/appContainerActions';
import { LinkToType } from '../../../services/constants/link';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { addTab, getActiveTabInfo, updateTabInfo, TYPE_LIST_VIEW, addNewFormTabs, getActiveTab, updateActiveTab, closeTab } from '../../../services/helper/sfTabManager';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import ShowCircularProgress from '../components/circularProgress';
import { dateFormat, dateFormatFlatPicker, formatCurrency } from '../../../services/helper/utils';
import { setHeader } from '../../../services/actions/headerActions';
import moment from 'moment';
import * as sfDialogs from '../components/sfDialogs';
import { hasAccessPermission, saveRecentRecord, getRecentRecord } from '../../../services/helper/common';
import { getCookie, setCookie } from '../../../services/helper/sfCookies';
import { Button, TextField, Chip, FormControl, Popover, Select as SelectField, MenuItem, InputLabel, Dialog } from '@mui/material';
import { refreshQuotation } from '../../../services/actions/detailViewActions';
import Autocomplete from '@mui/material/Autocomplete';
import NoRecords from '../components/noRecord';
import PopOver from './PopOver';
import _ from 'lodash';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";




const QuoteComponent = ({ object }) => {
    const quoteEditRefs = useRef(null);
    const dispatch = useDispatch();
    const [mounted, setMounted] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [toggle, setToggle] = useState(false);

    const [quoteObject, setQuoteObject] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenMore, setIsOpenMore] = useState(false);
    const [totalAmountState, setTotalAmountState] = useState(null);
    const [funcType, setFuncType] = useState('');
    const [royaltyObjArr, setRoyaltyObjArr] = useState({ objArr: [] });
    const [originalName, setOriginalName] = useState('');
    const [originalEmail, setOriginalEmail] = useState(null);
    const [id, setId] = useState(null);
    const [paymentActionOpen, setPaymentActionOpen] = useState(false);
    const [anchorPaymentActionEl, setAnchorPaymentActionEl] = useState(null);
    const [dueBal, setDueBal] = useState(null);

    const appState = useSelector((state) => state.app);
    const sfForm = useSelector((state) => state.sfForm);
    const quotation = useSelector((state) => state.quotation);
    const tab = useSelector((state) => state.tab);
    const userlist = useSelector((state) => state.userlist);
    const { id: userId, projectId } = appState.me;

    useEffect(() => {
        
        dispatch(setHeader(object, null, null, false, false));
        let activeTabInfo = getObjectParam(getActiveTabInfo());

        if (activeTabInfo.callFor === constants.ROYALTY_OBJECT) {
            getRoyaltyRecord();
        } else {
            getRecordInformation();
        }
    }, []);

    useEffect(() => {
        const getCategoryFromCookie = getCookie(`quotation_category_${quoteObject?.quoteid ?? 0}_${userId}_${projectId}`);
        const checkCategoryFromList = quoteObject?.categoryList?.some(value => {
            return value === getCategoryFromCookie
        })

        if (getCategoryFromCookie && (checkCategoryFromList || getCategoryFromCookie === '-9999')) {
            setQuoteObject((prev) => ({ ...prev, category: getCategoryFromCookie }));
        }


    }, [quoteObject?.categoryList?.length]);

    useEffect(() => {
        if (object === constants.QUOTATION_OBJECT && quotation.isRefresh) {
            getRecordInformation();
        }
        
    }, [quotation]);

    const getRoyaltyRecord = () => {

        var promise = null;
        try {
            let activeTabInfo = getObjectParam(getActiveTabInfo());
            var royaltyRecIds = getArrayParam(activeTabInfo.royalty_record_id);
            let url = endPoints.ROYALITY.GET_ROYALTY;
            let params = { royalty_recordids: royaltyRecIds };
            promise = Promise.resolve(HTTPClient.get(url, params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response) && response.status !== -1) {
                        if (response.hasOwnProperty('records')) {
                            let records = getArrayParam(response.records);
                            let objArray = [];
                            let royalTyObj = null;
                            records.forEach(e => {
                                objArray.push(e);
                            });
                            let temp = royaltyObjArr;
                            temp.objArr = objArray;
                            setRoyaltyObjArr({ ...temp });
                            getRecordInformation();
                        }

                    }
                })
            }
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> getRecordInformation()':" + error);
        }
    }

    const getRecordInformation = () => {
        let params = null;
        var promise = null;
        try {
            let activeTabInfo = getObjectParam(getActiveTabInfo());
            let call_from = getStringParam(activeTabInfo.object);
            params = {};
            if (call_from === constants.PAYMENTS_OBJECT) {
                params.paymentId = activeTabInfo.id;
            } else {
                params.quoteId = getIntParam(activeTabInfo.id);
            }
            params.parentRecordId = getIntParam(activeTabInfo.parentRecordId);
            params.parentTableId = getIntParam(OBJECT_TABLEID_MAP[getStringParam(activeTabInfo.parentObject)]);
            params.call_for = activeTabInfo.callFor;

            if (activeTabInfo.callFor === constants.ROYALTY_OBJECT) {
                params.isRoyaltyInvoice = true;
            }

            promise = Promise.resolve(HTTPClient.get(endPoints.QUOTATIONS.GET, params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (isValidParam(response) && response.status !== -1) {
                        let _quoteObject = getObjectParam(response.quote);
                        _quoteObject.userList = getArrayParam(userlist.data);
                        if (getBooleanParam(_quoteObject.isRoyaltyInvoice)) {
                            _quoteObject.lineiteminfo = getArrayParam(_quoteObject.lineiteminfo).filter(u => { return u.name !== "" });
                        }
                        if (activeTabInfo.type === constants.NEW || activeTabInfo.type === constants.COPY) {
                            setIsEditMode(true);
                            _quoteObject.quoteno = new Date().valueOf().toString();

                            var royaltyRecordId = 0;
                            if (activeTabInfo.type === 'New' && activeTabInfo.hasOwnProperty('royalty_record_id') && getArrayParam(activeTabInfo.royalty_record_id).length > 0) {
                                let lineiteminfo = getArrayParam(_quoteObject.lineiteminfo);
                                royaltyRecordId = getIntParam(activeTabInfo.royalty_record_id);
                                let tempRoyaltyObjArr = getArrayParam(royaltyObjArr.objArr);
                                let desc = "";
                                let uprice = 0;
                                let newOpt = {};
                                let strPrice = "";
                                let symbol = isValidParam(_quoteObject.currency) && _quoteObject.currency !== '' ? _quoteObject.currency : '$';
                                tempRoyaltyObjArr.forEach(royaltyObj => {

                                    desc = 'Royalty for ' + royaltyObj.start_date + " to " + royaltyObj.end_date + ' (' + royaltyObj.royalty_percent + '%' + ' of ' + symbol + formatCurrency(getFloatParam(royaltyObj.total)) + ')';
                                    uprice = royaltyObj.royalty_owed;
                                    newOpt = { desc: desc, name: 'Royalty  for ' + royaltyObj.start_date + " to " + royaltyObj.end_date, productid: "-99", qty: '1', tax: "No", uprice: uprice + '' };
                                    lineiteminfo.push(newOpt);
                                });
                                if (activeTabInfo.calculateType === "Monthly" || (activeTabInfo.calculateType === "Weekly" && activeTabInfo.selectedValue === "with")) {
                                    newOpt = {};
                                    strPrice = activeTabInfo.row.marketing_fee;
                                    strPrice = strPrice.replace(symbol, "");
                                    desc = activeTabInfo.marketingFeeLabel;
                                    uprice = parseFloat(strPrice);
                                    newOpt = { desc: desc, name: desc, productid: "-99", qty: '1', tax: "No", uprice: uprice + '' };
                                    lineiteminfo.push(newOpt);

                                    newOpt = {};
                                    strPrice = activeTabInfo.row.tech_fee;
                                    strPrice = strPrice.replace(symbol, "");
                                    desc = activeTabInfo.techFeeLabel;
                                    uprice = parseFloat(strPrice);
                                    newOpt = { desc: desc, name: 'Fee', productid: "-999", qty: '1', tax: "No", uprice: uprice + '' };
                                    lineiteminfo.push(newOpt);
                                }
                                lineiteminfo = lineiteminfo.filter(u => { return u.name !== "" });
                                newOpt = { desc: "", name: "", productid: "", qty: "", tax: "", uprice: '' };
                                lineiteminfo.push(newOpt);

                                _quoteObject.lineiteminfo = lineiteminfo;
                                _quoteObject.header = 'Invoice';
                            }

                            if (activeTabInfo.type === constants.COPY) {
                                _quoteObject.quoteid = 0;
                                let lineiteminfo = getArrayParam(_quoteObject.lineiteminfo).map(m => { return { ...m, id: 0 } });
                                _quoteObject.lineiteminfo = lineiteminfo;
                            }
                        }

                        if (activeTabInfo.callFor === constants.GENERATE_INVOICE) {
                            let lineiteminfo = getArrayParam(_quoteObject.lineiteminfo).map(m => { return { ...m, id: 0 } });
                            _quoteObject.lineiteminfo = lineiteminfo;
                        } else {
                            if (params.quoteId === 0) {
                                let cookieName = "LastQuoteSelectionType_" + appState.me.id + "_" + appState.me.projectId;
                                if (getCookie(cookieName) !== null && getCookie(cookieName) !== undefined && getCookie(cookieName) !== "") {
                                    _quoteObject.header = getStringParam(getCookie(cookieName));
                                }
                            }
                        }
                        setQuoteObject((prev) => ({ ...prev, ..._quoteObject }));
                        setOriginalName(_quoteObject.customerinfo['company']);
                        setOriginalEmail(_quoteObject.customerinfo['email']);
                        setId(getIntParam(_quoteObject.quoteid));

                        if (getIntParam(_quoteObject.quoteid) > 0) {
                            let recentParams = { recordId: _quoteObject.quoteid, type: "Object" };
                            saveRecentRecord(constants.QUOTATION_OBJECT, recentParams);
                            getRecentRecord(constants.QUOTATION_OBJECT);
                        }
                        setMounted(true);
                        refreshQuotation(false);
                    }
                })
            }
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> getRecordInformation()':" + error);
        }
    }

    const handleSend = (name) => {
        let url = null;
        let promise = null;
        let tempQuoteObject = getObjectParam(quoteObject);
        try {
            let html = generateQuoteTemplate('send');
            let quoteId = getIntParam(tempQuoteObject.quoteid);
            let params = {
                recordId: quoteId,
                type: tempQuoteObject.header,
                html

            }
            setIsLoading(true);
            url = endPoints.QUOTATIONS.CREATE_PDF;
            promise = Promise.resolve(HTTPClient.post(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let fileName = 'Quote.pdf';
                    if (isValidParam(tempQuoteObject.header) && tempQuoteObject.header !== '') {
                        fileName = getStringParam(tempQuoteObject.header) + '.pdf';
                    }
                    let fileUrl = getStringParam(response.data.url);
                    let data = {
                        email: getObjectParam(tempQuoteObject.customerinfo).email,
                        id: getIntParam(tempQuoteObject.parentRecordId),
                        object: getStringParam(TABLEID_OBJECT_MAP[tempQuoteObject.parentTableId]),
                        callFrom: constants.QUOTATION_OBJECT,
                        record_id: quoteId,
                        table_id: OBJECT_TABLEID_MAP[object],
                        request_status: getStringParam(name),
                        attachFiles: [{
                            name: fileName,
                            src: fileUrl
                        }],

                    }

                    let tempRoyaltyObjArr = getObjectParam(royaltyObjArr.objArr);
                    let royaltyIds = [];
                    if (tempRoyaltyObjArr.length > 0) {
                        tempRoyaltyObjArr.forEach(royaltyObj => {
                            if (royaltyObj.hasOwnProperty('id') && getIntParam(royaltyObj.id) > 0) {
                                royaltyIds.push(getIntParam(royaltyObj.id));
                                data.royaltyIds = royaltyIds;
                            }
                        });

                    }
                    let style = {};
                    style.width = '90%';
                    let labelName = getLocalizedStrings().label.COMMON.SEND_EMAIL;
                    dispatch(getAppCustomDrawer(true, labelName, LinkToType.TYPE_EMAIL, style, data, null, true));
                    setIsLoading(false);
                }
            })
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> handleSend()':" + error);
        }
    }

    const validate = (quoteObject) => {
        let isValidate = true;
        try {
            if (isValidParam(quoteObject)) {

                getArrayParam(quoteObject.lineiteminfo).forEach(e => {
                    e.name = e.name.trim();
                    if (e.productid === "-99" && e.name === "") {
                        e.name = 'Add a new product';
                    }
                });
                let lineiteminfo = getArrayParam(quoteObject.lineiteminfo);

                lineiteminfo = lineiteminfo.filter(u => { return u.name !== 'Add a new product' });

                let subtotal = 0;
                getArrayParam(quoteObject.lineiteminfo).forEach(e => {
                    subtotal = Number(subtotal) + (Number(e.qty) * Number(e.uprice));
                });
                subtotal = getFloatParam(subtotal);
                let grandtotal = getFloatParam((Number(subtotal) + Number(quoteObject.taskrate)) - Number(quoteObject.discount));
                let discount = Number(quoteObject.discount);
                if (quoteObject.salesRep === '') {
                    dispatch(showCustomSnackBar('Sales rep name cannot be blanked.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                } else if (lineiteminfo.length === 1 && lineiteminfo[0].name === '') {
                    dispatch(showCustomSnackBar('Please select one item information.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                } else if (discount > 0 && (discount > subtotal)) {
                    dispatch(showCustomSnackBar("The discount amount can't be greater than the subtotal.", styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                } else if (grandtotal < 0) {
                    dispatch(showCustomSnackBar('You must specify a transaction amount that is 0 or greater.', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                    return false;
                } else {
                    let lineInfoArr = getArrayParam(quoteObject.lineiteminfo);
                    let tempLineInfoArr = lineInfoArr.filter(u => { return u.name.indexOf(":") > -1 });

                    if (tempLineInfoArr.length > 0) {
                        dispatch(showCustomSnackBar('Product name cannot contain colon (:).', styles.snackbarBodyStyleError, styles.snackBarStyleTop));
                        return false;
                    }
                }
            }
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> validate()':" + error);
        }
        return isValidate;
    }

    const handleSave = () => {
        let params = null;
        var promise = null;
        let subtotal = 0;
        let tempQuoteObject;
        let billShipInfo;
        if (isValidParam(quoteEditRefs)) {
            tempQuoteObject = quoteEditRefs.current.quoteObjectData;
            billShipInfo = quoteEditRefs.current.customerBillToDetailsFields;
        }

        let isValid = validate(tempQuoteObject);
        if (isValid) {

            let parentQuote = quoteObject;
            tempQuoteObject.header = getStringParam(parentQuote.header);
            tempQuoteObject.category = getStringParam(parentQuote.category);
            tempQuoteObject.customerinfo['company'] = getStringParam(parentQuote.customerinfo['company']);
            tempQuoteObject.customerinfo['email'] = getStringParam(parentQuote.customerinfo['email']);

            tempQuoteObject.customerinfo['billto'] = getStringParam(billShipInfo.billto);
            tempQuoteObject.customerinfo['shipto'] = getStringParam(billShipInfo.shipto);
            //  tempQuoteObject.salesRep = tempQuoteObject.name;

            let activeTabInfo = getObjectParam(getActiveTabInfo());
            getArrayParam(tempQuoteObject.lineiteminfo).forEach(e => {
                subtotal = Number(subtotal) + (Number(e.qty) * Number(e.uprice));
            });
            subtotal = getFloatParam(subtotal);
            let grandtotal = getFloatParam((Number(subtotal) + Number(tempQuoteObject.taskrate)) - Number(tempQuoteObject.discount));
            tempQuoteObject.subtotal = getStringParam(subtotal);
            tempQuoteObject.grandtotal = getStringParam(grandtotal);

            let balanceDue = getFloatParam(Number(grandtotal) - Number(getFloatParam(tempQuoteObject.paymentAmount)));
            tempQuoteObject.balance_due = getStringParam(balanceDue);
            delete tempQuoteObject.productinfo;
            params = {
                id: getIntParam(tempQuoteObject.quoteid),
                quoteInfo: tempQuoteObject
            }
            if (getIntParam(tempQuoteObject.quoteid) === 0 && activeTabInfo.type === constants.NEW) {
                params.parentRecordId = tempQuoteObject.parentRecordId;
                params.parentTableId = tempQuoteObject.parentTableId;
            }
            tempQuoteObject.lineiteminfo = tempQuoteObject.lineiteminfo.filter(u => { return u.name !== 'Add a new product' });
            if (getBooleanParam(tempQuoteObject.isRoyaltyInvoice)) {
                tempQuoteObject.lineiteminfo.forEach((element, index) => {
                    if (element.productid === -99 || element.productid == -999 || element.productid == -9999) {
                        tempQuoteObject.lineiteminfo[index].non_inventory = true
                    }
                });
            }
            if (params.id === 0) {
                let cookieName = "LastQuoteSelectionType_" + appState.me.id + "_" + appState.me.projectId;
                setCookie(cookieName, getStringParam(tempQuoteObject.header));
            }
            setQuoteObject((prev)=>({...prev, ...tempQuoteObject}));


            if (activeTabInfo.callFor === constants.ROYALTY_OBJECT) {
                params.call_from = constants.ROYALTY_OBJECT;
                params.royalty_recordids = getArrayParam(activeTabInfo.royalty_record_id);
            }

            setIsLoading(true);
            promise = Promise.resolve(HTTPClient.post(endPoints.QUOTATIONS.SAVE, params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    if (response.status === 0) {
                        if (isValidParam(response.data)) {
                            let quoteObject = response.data.quote;
                            quoteObject.userList = getArrayParam(parentQuote.userList);
                            if (getBooleanParam(quoteObject.isRoyaltyInvoice)) {
                                quoteObject.lineiteminfo = getArrayParam(quoteObject.lineiteminfo).filter(u => { return u.name !== "" });
                            }
                            setQuoteObject((prev)=>({...prev, ...quoteObject}));
                            setIsEditMode(false);
                            setIsLoading(false);
                            setCookie(`quotation_category_${params.id}_${appState.me.id}_${appState.me.projectId}`, quoteObject?.category ?? '', constants.DEFAULT_COOKIE_EXPIRES);
                            dispatch(showCustomSnackBar(quoteObject.header === 'Invoice' ? 'Invoice saved successfully.' : 'Quotation saved successfully.', styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));

                            if (activeTabInfo.type === constants.NEW) {
                                activeTabInfo.type = constants.EDIT;
                                activeTabInfo.id = response.data.quote.quoteid;
                                delete activeTabInfo.parentRecordId;
                                delete activeTabInfo.parentObject;
                                if (activeTabInfo.callFor === constants.GENERATE_INVOICE) {
                                    delete activeTabInfo.callFor;
                                }
                                updateTabInfo(activeTabInfo);
                            }
                        }
                    } else if (response.status === -1) {
                        if (isValidParam(response.error.message) && response.error.message === 'Duplicate Product Name') {
                            let arrValue = getArrayParam(response.data);
                            let msg = "";
                            let msgArr = [];
                            if (arrValue.length > 0) {
                                arrValue.map(val => {
                                    msg += '<span>Product <b>' + val + '</b> already exists.<br /></span>';
                                    //msgArr.push(msg);
                                });
                                sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                                setIsLoading(false);
                            }
                        }

                    }
                })
            }
        }


    }

    const editQuote = () => {
        let drawerName = 'Edit Quote';
        let data = {};
        try {
            data.object = constants.PAYMENTS_OBJECT;
            data.id = getIntParam(quoteObject.quoteid);
            data.mode = 'edit';
            data.isDetailView = false;
            dispatch(getAppDrawer(true, drawerName, LinkToType.TYPE_QUOTATIONS, data, null));

        } catch (error) {
            console.error("Error in 'quoteComponent.js --> editQuote()':" + error);
        }
    }

    const processToAddQb = () => {
        try {
            let is_qbsetup = getBooleanParam(appState.me.is_qbsetup);
            if (!is_qbsetup) {
                sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, getLocalizedStrings().message.QUICKBOOKS.COONECT_QB_MSG, onConfirmDrawerOpen, null);
            } else {
                handleAddToQuickBooks();
            }
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> processToAddQb()':" + error);
        }
    }

    const onConfirmDrawerOpen = () => {
        dispatch(getAppDrawer(true, getLocalizedStrings().label.MY_INTEGRATION.MY_INTEGRATIONS, constants.MY_INTEGRATIONS, null, constants.QUOTATION_OBJECT));
    }

    const handleAddToQuickBooks = () => {
        let params = null;
        var promise = null;
        try {
            setIsLoading(true);
            let tempQuoteObject = quoteObject;
            params = {
                quoteId: getIntParam(tempQuoteObject.quoteid),
                parentRecordId: tempQuoteObject.parentRecordId,
                parentObject: getStringParam(TABLEID_OBJECT_MAP[tempQuoteObject.parentTableId]),
            }
            promise = Promise.resolve(HTTPClient.post(endPoints.QUOTATIONS.ADD_TO_QUICKBOOKS, params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    setIsLoading(false);
                    if (response.status === 0) {
                        let msgArr = [];
                        let msg = '';
                        if (response.hasOwnProperty('data')) {

                            if (response.data.hasOwnProperty('createdCompany')) {
                                let companyName = response.data['createdCompany'];
                                msg += "<span>Company <b>" + companyName + "</b> has been successfully posted to QuickBooks. <br /></span>";
                                //msgArr.push(msg);

                            }
                            if (response.data.hasOwnProperty('qbQuoteId')) {
                                let invoiceNo = null;
                                if (response.data.hasOwnProperty('createdInvoice')) {
                                    invoiceNo = response.data['createdInvoice'];
                                    msg += "<span>Invoice <b>" + invoiceNo + "</b> has been successfully posted to QuickBooks. <br /></span>";
                                    //msgArr.push(msg);

                                } else if (response.data.hasOwnProperty('updatedInvoice')) {
                                    invoiceNo = response.data['updatedInvoice'];
                                    msg += "<span>Invoice <b>" + invoiceNo + "</b> has been successfully updated to QuickBooks. <br /></span>";
                                    //msgArr.push(msg);
                                }

                                let qbQuoteId = getIntParam(response.data.qbQuoteId);
                                tempQuoteObject = quoteObject;
                                tempQuoteObject.qbQuoteId = qbQuoteId;
                                setQuoteObject((prev) => ({ ...prev, ...tempQuoteObject }));
                            }

                            if (response.data.hasOwnProperty('createdItemNameList')) {
                                let arrValue = getArrayParam(response.data.createdItemNameList);
                                if (arrValue.length > 0) {
                                    arrValue.forEach(val => {
                                        msg += "<span>Product <b>" + val + "</b> has been successfully posted to QuickBooks.<br /></span>";
                                        //msgArr.push(msg);
                                    });

                                }
                            }

                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                        }
                    } else if (response.status === -1) {
                        if (isValidParam(response.error.message) && response.error.message === 'We cannot Push') {
                            let msgArr = [];
                            let msg = '';

                            if (response.data.hasOwnProperty('createdCompany')) {
                                let companyName = response.data['createdCompany'];
                                msg += "<span>Company <b>" + companyName + "</b> has been successfully posted to QuickBooks. <br /></span>";
                                //msgArr.push(msg);

                            }

                            if (response.data.hasOwnProperty('createdItemNameList')) {
                                let arrValue = getArrayParam(response.data.createdItemNameList);
                                if (arrValue.length > 0) {
                                    arrValue.forEach(val => {
                                        msg += "<span>Product <b>" + val + "</b> has been successfully posted to QuickBooks.<br /></span>";
                                        //msgArr.push(msg);
                                    });

                                }
                            }

                            msg += "<span>We cannot post this invoice to QuickBooks.<br /></span>";
                            //msgArr.push("<span>We cannot post this invoice to QuickBooks.<br /></span>");

                            if (response.data.hasOwnProperty('greaterTransactionDate')
                                && getBooleanParam(response.data.greaterTransactionDate)) {
                                msg += "Please select a transaction date that comes after the date you started tracking the quantity on hand of these inventory items.";
                                //msgArr.push("<span>" + msg + "<br /></span>");
                            }
                            if (response.data.hasOwnProperty('inactiveQbItems')) {
                                let arrValue = getArrayParam(response.data.inactiveQbItems);
                                if (arrValue.length > 0) {
                                    arrValue.forEach(val => {
                                        msg += "<span><b>" + val + "</b> is inactive in QuickBooks.<br /></span>";
                                        //msgArr.push(msg);
                                    });
                                    msg += "<span>Please make the product(s) active in QuickBooks before posting the invoice.<br /></span>";
                                    //msgArr.push("<span>" + msg + "<br /></span>");
                                }
                            }

                            if (response.data.hasOwnProperty('lineItemHighPriceList')) {
                                let arrValue = getArrayParam(response.data.lineItemHighPriceList);
                                if (arrValue.length > 0) {
                                    arrValue.forEach(val => {
                                        msg += "<span><b>" + val + "</b> has an old price in QuickBooks.<br /></span>";
                                        //msgArr.push(msg);
                                    });
                                    msg += "<span> Please update the price of the product(s) in QuickBooks before posting the invoice.<br /></span>";
                                    //msgArr.push("<span>" + msg + "<br /></span>");
                                }
                            }

                            if (response.data.hasOwnProperty('qbErrorList')) {
                                let arrValue = getArrayParam(response.data.qbErrorList);
                                if (arrValue.length > 0) {
                                    arrValue.forEach(val => {
                                        msg += "<span>" + val + "<br /></span>";
                                        //msgArr.push(msg);
                                    });

                                }
                            }
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                        }
                    }
                })
            }
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> handleAddToQuickBooks()':" + error);
        }
    }

    const processToAddXero = () => {
        try {
            //let is_xero = getBooleanParam(this.props.app.me.is_xero);
            //if (is_xero) {
            handleAddToXero();
            //} else {
            //this.handleAddToQuickBooks();
            //}
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> processToAddQb()':" + error);
        }
    }

    const handleAddToXero = () => {
        let params = null;
        var promise = null;
        try {
            setIsLoading(true);
            let tempQuoteObject = quoteObject;
            params = {
                quoteId: getIntParam(tempQuoteObject.quoteid),
                parentRecordId: tempQuoteObject.parentRecordId,
                parentObject: getStringParam(TABLEID_OBJECT_MAP[tempQuoteObject.parentTableId]),
            }
            promise = Promise.resolve(HTTPClient.post(endPoints.QUOTATIONS.ADD_TO_XERO, params));
            if (isValidParam(promise)) {
                promise.then((response) => {
                    setIsLoading(false);
                    if (response.status === 0) {
                        let msgArr = [];
                        let msg = '';
                        if (response.hasOwnProperty('data')) {

                            if (response.data.hasOwnProperty('createdCompany')) {
                                let companyName = response.data['createdCompany'];
                                msg += "<span>Company <b>" + companyName + "</b> has been successfully posted to Xero. <br /></span>";
                                //msgArr.push(msg);

                            }
                            if (response.data.hasOwnProperty('qbQuoteId')) {
                                if (response.data.hasOwnProperty('createdInvoice')) {
                                    let invoiceNo = response.data['createdInvoice'];
                                    msg += "<span>Invoice <b>" + invoiceNo + "</b> has been successfully posted to Xero. <br /></span>";
                                    //msgArr.push(msg);

                                }

                                let qbQuoteId = getIntParam(response.data.qbQuoteId);
                                tempQuoteObject.qbQuoteId = qbQuoteId;
                                setQuoteObject((prev)=>({ ...prev, ...tempQuoteObject }));
                            }

                            if (response.data.hasOwnProperty('createdItemNameList')) {
                                let arrValue = getArrayParam(response.data.createdItemNameList);
                                if (arrValue.length > 0) {
                                    arrValue.forEach(val => {
                                        msg += "<span>Product <b>" + val + "</b> has been successfully posted to Xero.<br /></span>";
                                        //msgArr.push(msg);
                                    });

                                }
                            }

                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                        }
                    } else if (response.status === -1) {
                        if (isValidParam(response.error.message) && response.error.message === 'We cannot Push') {
                            let msgArr = [];
                            let msg = '';

                            if (response.data.hasOwnProperty('createdCompany')) {
                                let companyName = response.data['createdCompany'];
                                msg += "<span>Company <b>" + companyName + "</b> has been successfully posted to Xero. <br /></span>";
                                //msgArr.push(msg);

                            }

                            if (response.data.hasOwnProperty('createdItemNameList')) {
                                let arrValue = getArrayParam(response.data.createdItemNameList);
                                if (arrValue.length > 0) {
                                    arrValue.forEach(val => {
                                        msg += "<span>Product <b>" + val + "</b> has been successfully posted to Xero.<br /></span>";
                                        //msgArr.push(msg);
                                    });

                                }
                            }

                            msg += "<span>We cannot post this invoice to Xero.<br /></span>";

                            if (response.data.hasOwnProperty('greaterTransactionDate')
                                && getBooleanParam(response.data.greaterTransactionDate)) {
                                msg += "<span>Please select a transaction date that comes after the date you started tracking the quantity on hand of these inventory items.<br /></span>";
                                //msgArr.push("<span>" + msg + "<br /></span>");
                            }
                            if (response.data.hasOwnProperty('inactiveQbItems')) {
                                let arrValue = getArrayParam(response.data.inactiveQbItems);
                                if (arrValue.length > 0) {
                                    arrValue.forEach(val => {
                                        msg += "<span><b>" + val + "</b> is inactive in Xero.<br /></span>";
                                        //msgArr.push(msg);
                                    });
                                    msg += "<span> Please make the product(s) active in pRecordId before posting the invoice.<br /></span>";
                                    //msgArr.push("<span>" + msg + "<br /></span>");
                                }
                            }

                            if (response.data.hasOwnProperty('lineItemHighPriceList')) {
                                let arrValue = getArrayParam(response.data.lineItemHighPriceList);
                                if (arrValue.length > 0) {
                                    arrValue.forEach(val => {
                                        msg += "<span><b>" + val + "</b> has an old price in pRecordId.<br /></span>";
                                        //msgArr.push(msg);
                                    });
                                    msg += "<span>Please update the price of the product(s) in pRecordId before posting the invoice.<br /></span>";
                                    //msgArr.push("<span>" + msg + "<br /></span>");
                                }
                            }

                            if (response.data.hasOwnProperty('qbErrorList')) {
                                let arrValue = getArrayParam(response.data.qbErrorList);
                                if (arrValue.length > 0) {
                                    arrValue.forEach(val => {
                                        msg += "<span>" + val + "<br /></span>";
                                        //msgArr.push(msg);
                                    });

                                }
                            }
                            sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, msg, null);
                        }
                    }
                })
            }
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> handleAddToQuickBooks()':" + error);
        }
    }

    const addPayment = () => {
        try {
            let tempQuoteObject = quoteObject;
            let drawerName = 'Add New Payment';
            let obj = object;
            let data = {};
            data.object = constants.PAYMENTS_OBJECT;
            data.id = null;
            data.mode = '';
            data.parent_object = getStringParam(TABLEID_OBJECT_MAP[tempQuoteObject.parentTableId]);
            data.parent_record_id = tempQuoteObject.parentRecordId;
            data.recordId = 0;
            data.isDetailView = false;
            data.callFrom = 'quotations';

            let lineiteminfo = getArrayParam(tempQuoteObject.lineiteminfo);

            let subtotal = 0;
            lineiteminfo.forEach(e => {
                subtotal = Number(subtotal) + (Number(e.qty) * Number(e.uprice));
            });
            subtotal = getFloatParam(subtotal);
            let taskrate = getFloatParam(tempQuoteObject.taskrate);
            let grandtotal = getFloatParam((Number(subtotal) + Number(taskrate)) - Number(tempQuoteObject.discount));
            let balanceDue = getFloatParam(Number(grandtotal) - Number(getFloatParam(tempQuoteObject.paymentAmount)));

            let activeTabInfo = getObjectParam(getActiveTabInfo());
            activeTabInfo.quotation_id = getIntParam(quoteObject.quoteid);
            activeTabInfo.quotation_number = quoteObject.quoteno;
            activeTabInfo.quotation_category = quoteObject.category != null && quoteObject.category != "" && quoteObject.category != '-9999' ? quoteObject.category : "";
            activeTabInfo.balanceDue = balanceDue;
            activeTabInfo.callFrom = 'quotations';
            updateTabInfo(activeTabInfo);

            dispatch(getAppDrawer(true, drawerName, constants.NEW, data, null));

        } catch (error) {
            console.error("Error in 'quoteComponent.js --> addPayment()':" + error);
        }
    }

    const handleConvertToInvoice = () => {
        let params = null;
        var promise = null;
        try {
            if (getIntParam(quoteObject.quoteid) > 0) {
                params = {
                    quoteId: getIntParam(quoteObject.quoteid),
                    source: 'Invoice'
                }
                promise = Promise.resolve(HTTPClient.get(endPoints.QUOTATIONS.UPDATE_FIELDS, params));
                if (isValidParam(promise)) {
                    promise.then((response) => {
                        if (response.status !== -1) {
                            dispatch(showCustomSnackBar('Converted to Invoice.', styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop));
                            let tempQuoteObject = quoteObject;
                            tempQuoteObject.header = 'Invoice';
                            setQuoteObject((prev) => ({ ...prev, ...tempQuoteObject }));
                        }
                    })
                }
            }
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> handleConvertToInvoice()':" + error);
        }
    }

    const downloadPdf = () => {
        let url = null;
        let promise = null;
        try {
            let html = generateQuoteTemplate();
            let params = { html };
            setIsLoading(true);
            url = endPoints.QUOTATIONS.DOWNLOAD_PDF;
            promise = Promise.resolve(HTTPClient.get(url, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    var blob = new Blob([response], { type: "application/pdf" });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'Quote.pdf';
                    link.click();
                    setIsLoading(false);
                }
            })
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> downloadPdf()':" + error);
        }
    }

    const handleActions = (name) => {
        try {
            if (name === 'save') {
                handleSave();
            } else if (name === 'modify') {
                editQuote();
            } else if (name === 'send' || name === 'Request Approval') {
                handleSend(name);
            } else if (name === 'print') {
                handlePrint();
            } else if (name === 'convertToInvoice') {
                handleConvertToInvoice();
            } else if (name === 'addQuickbooks') {
                processToAddQb();
            } else if (name === 'addXero') {
                processToAddXero();
            } else if (name === 'addPayment') {
                addPayment();
            } else if (name === 'edit') {
                let hasPermission = hasAccessPermission(constants.QUOTATION_OBJECT, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT);
                if (hasPermission) {
                    changeEditMode(name);
                } else {
                    let permissionMessage = getLocalizedStrings().message.COMMON.ACCESS_DENIED;
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, permissionMessage)
                }
            } else if (name === 'download') {
                downloadPdf();
            } else if (name === 'cancel') {
                changeEditMode(name);
            } else if (name === 'copy') {
                handleCopy(name);
            } else if (name === 'addJobs') {
                addJobs();
            }
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> handleActions()':" + error);
        }
    }

    const addJobs = () => {
        let tempquoteObject = quoteObject;
        try {
            addNewFormTabs({ id: 0, title: 'Add Jobs', type: constants.NEW, object: constants.JOBS_OBJECT, parentRecordId: tempquoteObject.parentRecordId, parentObject: getStringParam(TABLEID_OBJECT_MAP[tempquoteObject.parentTableId]), callFrom: constants.QUOTATION_OBJECT, quoteId: tempquoteObject.quoteid });
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> addJobs()':" + error);
        }
    }

    const handleCopy = (name) => {
        let tempQuoteObject = quoteObject;
        setFuncType(name);

        try {
            let tempQuoteNo = new Date().valueOf().toString();
            tempQuoteObject.quoteid = 0;
            tempQuoteObject.quoteno = tempQuoteNo;
            tempQuoteObject.paymentAmount = 0;
            let lineiteminfo = getArrayParam(tempQuoteObject.lineiteminfo).map(m => { return { ...m, id: 0 } });
            tempQuoteObject.lineiteminfo = lineiteminfo;
            tempQuoteObject.status = 'New';
            setQuoteObject((prev) => ({ ...prev, ...tempQuoteObject }));
            setIsEditMode(true);
            let tab = getActiveTab();
            tab.label = tempQuoteNo;
            updateActiveTab(tab);
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> handleCopy()':" + error);
        }
    }

    const changeEditMode = (name) => {
        try {

            setIsEditMode(!getBooleanParam(isEditMode));

            setFuncType(name);
            let tempQuoteObject = quoteObject;
            tempQuoteObject.customerinfo['company'] = originalName
            tempQuoteObject.customerinfo['email'] = originalEmail
            setQuoteObject((prev) => ({ ...prev, ...tempQuoteObject }));
            setTotalAmountState(null);
            if (isEditMode && getIntParam(quoteObject.quoteid) === 0) {
                let activeTab = getActiveTab();
                if (isValidParam(activeTab)) {
                    closeTab(activeTab.item, activeTab.index);
                }
            }
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> changeEditMode()':" + error);
        }
    }

    const generateHeaderbuttons = () => {
        let element = [];
        let tempquoteObject = quoteObject;
        try {
            let list = [];
            if (isEditMode) {
                if (tempquoteObject.hasOwnProperty('tenantinfo')) {
                    list = [{ name: 'save', label: getLocalizedStrings().label.COMMON.SAVE }];
                    list.push({ name: 'cancel', label: getLocalizedStrings().label.COMMON.CANCEL });
                }
            }

            element = list.map(item => {
                let buttonStyle = item.name === 'cancel' ? { ...styles.secondaryButton, verticalAlign: 'top', width: '100%', height: '38px', } : { ...styles.primaryButton, verticalAlign: 'top', width: '100%', height: '38px', }
                return (
                    <div style={{ display: 'grid' }}>
                        <Button
                            key={`key-${item.name}`}
                            onClick={() => handleActions(item.name)}
                            label={item.label}
                            style={buttonStyle}
                            labelstyle={{ fontWeight: 'normal' }}
                            disabled={isLoading}
                        > {item.label}</Button>
                    </div>

                );
            });
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> generateHeaderbuttons()':" + error);
        }
        return element;
    }

    const handlePrint = () => {
        try {
            let divContents = generateQuoteTemplate('send');
            var a = window.open('', '', 'height=1000, width=1000');
            a.document.write(divContents);
            a.document.close();
            a.print();
            // a.close();

        } catch (error) {
            console.error("Error in 'quoteComponent.js --> handlePrint()':" + error);
        }
    }

    const generateQuoteTemplate = (callFor) => {
        let template = null;
        let lineiteminfo = getArrayParam(quoteObject.lineiteminfo);
        let productRowList = quoteObject.isRoyaltyInvoice ? ['name', 'desc', 'amount'] : ['name', 'desc', 'qty', 'uprice', 'amount'];
        try {
            if (quoteObject.isRoyaltyInvoice == false) {
                template = `
    <html>
        <head> 
            <style>
                table, tr, td, th, tbody, thead, tfoot {
                    page-break-inside: avoid !important;
                }
                @media print {
                    .col-sm-4, .col-sm-8, .col-sm-12 {
                         float: left;
                    }
                    .col-sm-4 {
                        width: 33.33333333333333%;
                    }
                    .col-sm-8 {
                        width: 66.66666666666666%;
                    }
                    .margin-5 {
                        margin-top: 15px;
                    }
                    .tenentAddress {
                        padding-top: -4em;
                    }
                    .fr {
                        float: right;
                        border: 1px solid black;
                    }
                    .col-sm-12 {
                        width: 100%;
                    }
                    .break {
                        overflow-wrap: break-word;
                    }
                }
                .col-sm-4, .col-sm-8 {
                    float: left;
                }
                .col-sm-4 {
                    width: 33.33333333333333%;
                }
                .col-sm-8 {
                    width: 66.66666666666666%;
                }
                .margin-5 {
                    margin-top: 15px;
                }
                .break {
                    overflow-wrap: break-word;
                }
            </style>
        </head>
        <body>
            <div style="padding-left: 3%;">
                   TEMPLATE_HEADER
                <div class="row">
                    <div class="col-sm-4 margin-5" style="color: #97989e;">Customer</div>
                    <div class="col-sm-4 margin-5" style="color: #97989e;">Email</div>
                    <div class="col-sm-4 margin-5" style="color: #97989e;">Sales Rep</div>
                </div>
                <div class="row">
                    <div class="col-sm-4 break">customer_name</div>
                    <div class="col-sm-4 break">customer_email</div>
                    <div class="col-sm-4 break">salesRep</div>
                </div>
                <div class="row" style="margin-top: 1em;">
                    <div class="col-sm-4 margin-5" style="color: #97989e;">Bill To</div>
                    <div class="col-sm-4 margin-5" style="color: #97989e;">Ship To</div>
                    <div class="col-sm-4 margin-5" style="color: #97989e;">Terms</div>
                </div>
                <div class="row">
                    BILL_TO
                    SHIP_TO
                    <div class="col-sm-4">
                        terms <br /><br />
                        <span style="color: #97989e;">DATE</span> <br />
                        quotedate <br /><br />
                        <span style="color: #97989e;">DUE_DATE</span> <br />
                        quotevalid <br />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12"></div>
                </div>
                <div style="padding: 10px; margin-top: .1em;">&nbsp;</div>
                <table style="width: 100%; border-collapse: inherit; border-spacing: 2px;">
                    <tbody style="text-align: center; height: 28px;">
                        <tr style="height: 30px; color: #333; padding: 5px; font-weight:bold">
                            <td style="width: 20%; border: 1px solid #908989;border-radius: 10px;background-color: #ccc;">ITEM</td>
                            <td style="width: 25%; border: 1px solid #908989;border-radius: 10px;background-color: #ccc;">DESCRIPTION</td>
                            <td style="width: 15%; border: 1px solid #908989;border-radius: 10px;background-color: #ccc;">QTY</td>
                            <td style="width: 20%; border: 1px solid #908989;border-radius: 10px;background-color: #ccc;">RATE</td>
                            <td style="width: 15%; border: 1px solid #908989;border-radius: 10px;background-color: #ccc;">AMOUNT</td>
                        </tr>
                        productTable
                        <tr style="height: 10px;">
                            <td colspan="5"></td>
                        </tr>
                        <tr style="height: inherit;">
                            <td colspan="3"></td>
                            <td style="font-weight: bold;">SUBTOTAL</td>
                            <td style="padding: 3px;">$subtotal</td>
                        </tr>  
                        DISCOUNT  
                        <tr style="height: inherit;">
                            <td colspan="3"></td>
                            <td style="font-weight: bold;">TAX</td>
                            <td style="padding: 3px;">$taskrate</td>
                        </tr>                         
                        <tr style="height: inherit;">
                            <td colspan="3"></td>
                            <td style="font-weight: bold;">TOTAL</td>
                            <td style="padding: 3px;">$grandtotal</td>
                        </tr>
                        PAYMENT_AMOUNT
                        REFUNDED_AMOUNT
                        <tr style="height: inherit;">
                            <td colspan="3"></td>
                            <td style="font-weight: bold;">BALANCE DUE</td>
                            <td style="padding: 3px;">$balancedue</td>
                        </tr>
                    </tbody>
                </table>
                    NOTES_ELEMENTS
            </div>
        </body>
    </html>                
    `;
            } else {
                template = `
    <html>
        <head> 
            <style>
                table, tr, td, th, tbody, thead, tfoot {
                    page-break-inside: avoid !important;
                }
                @media print {
                    .col-sm-4, .col-sm-8, .col-sm-12 {
                         float: left;
                    }
                    .col-sm-4 {
                        width: 33.33333333333333%;
                    }
                    .col-sm-8 {
                        width: 66.66666666666666%;
                    }
                    .margin-5 {
                        margin-top: 15px;
                    }
                    .tenentAddress {
                        padding-top: -4em;
                    }
                    .fr {
                        float: right;
                        border: 1px solid black;
                    }
                    .col-sm-12 {
                        width: 100%;
                    }
                    .break {
                        overflow-wrap: break-word;
                    }
                }
                .col-sm-4, .col-sm-8 {
                    float: left;
                }
                .col-sm-4 {
                    width: 33.33333333333333%;
                }
                .col-sm-8 {
                    width: 66.66666666666666%;
                }
                .margin-5 {
                    margin-top: 15px;
                }
                .break {
                    overflow-wrap: break-word;
                }
            </style>
        </head>
        <body>
            <div style="padding-left: 3%;">
                   TEMPLATE_HEADER
                <div class="row">
                    <div class="col-sm-4 margin-5" style="color: #97989e;">Customer</div>
                    <div class="col-sm-4 margin-5" style="color: #97989e;">Email</div>
                    <div class="col-sm-4 margin-5" style="color: #97989e;">Sales Rep</div>
                </div>
                <div class="row">
                    <div class="col-sm-4 break">customer_name</div>
                    <div class="col-sm-4 break">customer_email</div>
                    <div class="col-sm-4 break">salesRep</div>
                </div>
                <div class="row" style="margin-top: 1em;">
                    <div class="col-sm-4 margin-5" style="color: #97989e;">Bill To</div>
                    <div class="col-sm-4 margin-5" style="color: #97989e;">Ship To</div>
                    <div class="col-sm-4 margin-5" style="color: #97989e;">Terms</div>
                </div>
                <div class="row">
                    BILL_TO
                    SHIP_TO
                    <div class="col-sm-4">
                        terms <br /><br />
                        <span style="color: #97989e;">DATE</span> <br />
                        quotedate <br /><br />
                        <span style="color: #97989e;">DUE_DATE</span> <br />
                        quotevalid <br />
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12"></div>
                </div>
                <div style="padding: 10px; margin-top: .1em;">&nbsp;</div>
                <table style="width: 100%; border-collapse: inherit; border-spacing: 2px;">
                    <tbody style="text-align: center; height: 28px;">
                        <tr style="height: 30px; color: #333; padding: 5px; font-weight:bold">
                            <td style="width: 20%; border: 1px solid #908989;border-radius: 10px;background-color: #ccc;">ITEM</td>
                            <td style="width: 25%; border: 1px solid #908989;border-radius: 10px;background-color: #ccc;">DESCRIPTION</td>
                             <td style="width: 15%; border: 1px solid #908989;border-radius: 10px;background-color: #ccc;">AMOUNT</td>
                        </tr>
                        productTable
                        <tr style="height: 10px;">
                            <td colspan="5"></td>
                        </tr>
                        <tr style="height: inherit;">
                            <td colspan="1"></td>
                            <td style="font-weight: bold;">SUBTOTAL</td>
                            <td style="padding: 3px;">$subtotal</td>
                        </tr>  
                        DISCOUNT  
                        <tr style="height: inherit;">
                            <td colspan="1"></td>
                            <td style="font-weight: bold;">TAX</td>
                            <td style="padding: 3px;">$taskrate</td>
                        </tr>                         
                        <tr style="height: inherit;">
                            <td colspan="1"></td>
                            <td style="font-weight: bold;">TOTAL</td>
                            <td style="padding: 3px;">$grandtotal</td>
                        </tr>
                        PAYMENT_AMOUNT
                        REFUNDED_AMOUNT
                        <tr style="height: inherit;">
                            <td colspan="1"></td>
                            <td style="font-weight: bold;">BALANCE DUE</td>
                            <td style="padding: 3px;">$balancedue</td>
                        </tr>
                    </tbody>
                </table>
                    NOTES_ELEMENTS
            </div>
        </body>
    </html>                
    `;
            }


            let templateHeader = '';
            if (getStringParam(callFor) === 'send') {
                templateHeader = `
                <table style="width: 100%; margin-bottom: 2em;">
                <tbody>
                <tr><td> </td><td></td></tr>
                <tr style="height: 35px;">
                    <td colspan="4" style="font-weight: bold; font-size: 30px;">header #quoteno</td>                             
                </tr>
                <tr>
                    <td rowspan="5" style="text-align: left; width:10%"><img src="${quoteObject.logourl}" style=" max-width: 160px; max-height: 140px;" /></td>
                    <td colspan="2" style="font-weight: bold; font-size: 17px; width: 60%; text-align: left; padding-left:20px;">tenantinfo-company</td>
                    <td colspan="2" style="text-align: center; width: 40%; border: 0px solid black; margin-left: 4em;">
                        <table style="border: 0px solid blue; float: right;">
                        <tr style="font-size: 30px; font-weight: bold; padding-top: 16px; text-align: left; padding-left: 20px;">
                        <td><span>APPROVED_STATUS</span></td>
                    </tr>
                        <tr style="text-align: left; padding-left: 20px;">
                          <td>Balance Due</td>
                            </tr>
                            <tr style="font-size: 30px; font-weight: bold; padding-top: 16px; text-align: left; padding-left: 20px;">
                                <td><span>$BALANCE_DUE_VALUE</span></td>
                            </tr>
                                                                            
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colspan="2" class="tenentAddress" style="padding-left:20px; margin-top: -10px">tenantinfo-address</td>
                    <td colspan="2"></td>
                </tr>
                <tr style="height: inherit;">
                    <td colspan="2" style="padding-left:20px;" >tenantinfo-phone</td>
                    <td colspan="2"></td>
                </tr>
                <tr style="height: inherit;">
                    <td colspan="2" style="padding-left:20px;" >tenantinfo-email</td>
                    <td colspan="2"></td>
                </tr>
            </tbody>
            </table>`;
            }
            template = template.replace('TEMPLATE_HEADER', templateHeader);

            let tempQuoteObject = quoteObject;
            let productTableRow = '';
            let subtotal = '';
            lineiteminfo.forEach((item, index) => {
                let productTableData = '';
                if (item.name !== '') {
                    productRowList.forEach(key => {
                        let textAlign = (key === 'name' || key === 'desc') ? 'center' : 'center';
                        let value = item[key];
                        if (key === 'amount') {
                            value = Number(item['qty']) * Number(item['uprice']);
                            subtotal = Number(subtotal) + Number(value);
                        }
                        if (key === 'amount' || key === 'uprice') {
                            value = (value !== '' || value !== 0) ? '$' + formatCurrency(value) : '';
                        }
                        productTableData += `<td style="padding: 10px; border: 1px solid #908989; border-radius: 10px; text-align: ${textAlign};">${value}</td>`
                    });
                    productTableRow += `<tr style="padding: 5px; height: inherit;">${productTableData}</tr>`
                }
            });
            let discountTR = ` <tr style="height: inherit;">
                               <td colspan=${quoteObject.isRoyaltyInvoice == true ? "1": "3"}></td>
                                <td style="font-weight: bold;">DISCOUNT</td>
                                <td style="padding: 3px;">$discount</td>
                             </tr>`

            subtotal = getFloatParam(subtotal);
            let grandtotal = getFloatParam((Number(subtotal) + Number(tempQuoteObject.taskrate)) - Number(tempQuoteObject.discount));
            let paymentList = getArrayParam(tempQuoteObject.paymentList);
            let refundedPaymentList = paymentList.filter(u => { return u.payment_type === "Refund" });
            let refundedAmount = 0;
            if (refundedPaymentList.length > 0) {
                refundedPaymentList.map((items, Index) => {
                    if (Index === 0) {
                        refundedAmount = (Number(items.payment_value));
                    } else {
                        refundedAmount = (refundedAmount + (Number(items.payment_value)));
                    }
                })
                refundedAmount = getFloatParam(refundedAmount);
            }
            if (refundedAmount > 0) {
                blanceDue = getFloatParam(Number(blanceDue) + Number(refundedAmount));
            }
            let blanceDue = getFloatParam(Number(grandtotal) - Number(getFloatParam(tempQuoteObject.paymentAmount)));
            let taskrate = formatCurrency(getFloatParam(tempQuoteObject.taskrate));
            subtotal = formatCurrency(subtotal);
            grandtotal = formatCurrency(grandtotal);
            blanceDue = formatCurrency(blanceDue);
            let status = quoteObject.status === 'Approved' ? 'APPROVED' : '';
            let customerInfo = tempQuoteObject.customerinfo;
            let billTr = '<div class="col-sm-4">';
            let shipTr = '<div class="col-sm-4">';
            if (isValidParam(customerInfo)) {
                let billToInfo = customerInfo['billto'];
                let shipToInfo = customerInfo['shipto'];
                let billToInfoArr = billToInfo.split('\n');
                let shipToInfoArr = shipToInfo.split('\n');
                let maxArray = billToInfoArr.length > shipToInfoArr.length ? billToInfoArr : shipToInfoArr;
                maxArray.forEach((ob, index) => {
                    billTr = billTr + getStringParam(billToInfoArr[index]) + `<br />`
                    shipTr = shipTr + getStringParam(shipToInfoArr[index]) + `<br />`
                });

            }
            template = template.replace('BILL_TO', billTr + '</div>');
            template = template.replace('SHIP_TO', shipTr + '</div>');

            template = template.replace('productTable', productTableRow);
            template = template.replace('subtotal', subtotal);
            template = template.replace('grandtotal', grandtotal);
            template = template.replaceAll('balancedue', blanceDue);
            template = template.replace('taskrate', taskrate);
            template = template.replace('customer_name', getStringParam(customerInfo['company']));
            template = template.replace('customer_email', getStringParam(customerInfo['email']));
            template = template.replace('APPROVED_STATUS', status);
            template = template.replace('BALANCE_DUE_VALUE', blanceDue);

            let dueDateLabel = tempQuoteObject.header === 'Invoice' ? 'Due Date' : 'Expiration Date';
            let dateLabel = tempQuoteObject.header === 'Invoice' ? 'Invoice Date' : 'Quote Date';
            template = template.replace('DATE', dateLabel);
            template = template.replace('DUE_DATE', dueDateLabel);

            let paymentAmount = Number(getFloatParam(tempQuoteObject.paymentAmount));
            let paymentTR = ` <tr style="height: inherit;">
                                <td colspan=${quoteObject.isRoyaltyInvoice == false ? "3": "1"}></td>
                                <td style="font-weight: bold;">AMOUNT RECEIVED</td>
                                <td style="padding: 3px;">$paymentAmount</td>
                            </tr>`;

            let PaymentTR1 = paymentAmount > 0 ? paymentTR.replace('paymentAmount', formatCurrency(getFloatParam(paymentAmount))) : '';
            template = template.replace('PAYMENT_AMOUNT', PaymentTR1);

            let refundTR = ` <tr style="height: inherit;">
            <td colspan="3"></td>
            <td style="font-weight: bold;">REFUNDED</td>
            <td style="padding: 3px;">$refundedAmount</td>
            </tr>`;
            let refundTR1 = refundedAmount > 0 ? refundTR.replace('refundedAmount', formatCurrency(getFloatParam(refundedAmount))) : '';
            template = template.replace('REFUNDED_AMOUNT', refundTR1);

            let noteText = tempQuoteObject.notetext;
            let noteTextArr = noteText.split("\n");
            let notesElement = [];
            if (callFor === 'send') {
                noteTextArr.forEach((value, index) => {
                    notesElement.push(`<tr style="height: 30px;">
                    <td colspan="4"  style="padding: 5px; float:left;white-space: pre-wrap;" >`+ value + `</td> </tr>`);
                })
                notesElement = `  <table style="width: 100%;"><tbody>` + notesElement + `</tbody> </table>`
            } else {
                notesElement = ` <div>
                <div colspan="4" rowspan="3" style="padding: 5px; float:left;white-space: pre-wrap;">`+ noteText + `</div>
            </div>`
            }

            template = template.replace('NOTES_ELEMENTS', notesElement);

            Object.keys(tempQuoteObject).forEach(name => {
                if (name === 'customerinfo') {
                    let tdInfo = `  <td>info</td>`;
                    let emptyCustomInfoArr = [];
                    Object.keys(tempQuoteObject['customerinfo']).forEach(ele => {
                        if (getStringParam(tempQuoteObject.customerinfo[ele]) === '') {
                            emptyCustomInfoArr.unshift('customerinfo-' + ele);
                        } else if (getStringParam(tempQuoteObject.customerinfo[ele]) !== '' && emptyCustomInfoArr.length === 0) {
                            template = template.replace('customerinfo-' + ele, getStringParam(tempQuoteObject.customerinfo[ele]));
                        } else if (getStringParam(tempQuoteObject.customerinfo[ele]) !== '' && emptyCustomInfoArr.length !== 0) {
                            template = template.replace(emptyCustomInfoArr[emptyCustomInfoArr.length - 1], getStringParam(tempQuoteObject.customerinfo[ele]));
                            emptyCustomInfoArr.pop();
                            emptyCustomInfoArr.unshift('customerinfo-' + ele);
                        }
                    });
                    if (emptyCustomInfoArr.length > 0) {
                        emptyCustomInfoArr.forEach((info) => {
                            template = template.replace(info, '');
                        })
                    }
                } else if (name === 'tenantinfo') {
                    Object.keys(tempQuoteObject['tenantinfo']).forEach(ele => {
                        template = template.replace('tenantinfo-' + ele, getStringParam(tempQuoteObject.tenantinfo[ele]));
                    });
                } else {
                    if (name === 'discount') {
                        template = getFloatParam(tempQuoteObject.discount) > 0 ? template.replace('DISCOUNT', discountTR).replace('discount', formatCurrency(getStringParam(tempQuoteObject[name]))) : template.replace('DISCOUNT', '');
                    } else if (name === 'currency') {
                        template = template.replace(/\$/g, getStringParam(tempQuoteObject[name]));
                    } else {
                        template = template.replace(name, getStringParam(tempQuoteObject[name]));
                    }
                    if (name === 'header') {
                        template = template.replaceAll(name.toUpperCase(), getStringParam(tempQuoteObject[name].toUpperCase()));
                    }
                }
            })
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> generateQuoteTemplate()':" + error);
            template = '';
        }
        return template;
    }

    const handlePaymentActionClose = () => {
        setPaymentActionOpen(false);
    }

    const handlePaymentActionOpen = (event) => {
        setPaymentActionOpen(true);
        setAnchorPaymentActionEl(event.currentTarget);
    }

    const getQuoteStatusElements = () => {
        let elements = [];
        let tempQuoteObject = quoteObject;
        try {
            if (getStringParam(tempQuoteObject.status) !== 'New' && getStringParam(tempQuoteObject.status) !== 'Sent' && getStringParam(tempQuoteObject.status) !== '')
                elements = <div>{'(' + getStringParam(tempQuoteObject.status) + ')'}</div>


        } catch (error) {
            console.error("Error in 'quoteComponent.js -> getQuoteStatusElements()':" + error);
        }
        return elements;
    }

    const getQuoteStatusElementsForApproved = () => {
        let elements = [];
        let tempQuoteObject = quoteObject;
        try {
            if (getStringParam(tempQuoteObject.status) === 'Approved')
                elements = <div style={{ fontSize: '45px', fontWeight: 'bold' }}>APPROVED</div>

        } catch (error) {
            console.error("Error in 'quoteComponent.js -> getQuoteStatusElements()':" + error);
        }
        return elements;
    }

    const createPaymentDetails = () => {
        let elements = [];
        let tempQuoteObject = quoteObject;
        try {
            let paymentList = getArrayParam(tempQuoteObject.paymentList);
            paymentList = paymentList.filter(u => { return u.payment_type !== "Refund" });
            let paymentMode = paymentList.length + " payment(s) made (" + tempQuoteObject.currency + " " + formatCurrency(getFloatParam(tempQuoteObject.paymentAmount)) + ")";
            elements = <div style={{
            }}>
                <div style={{ marginTop: '-25px' }}>
                    <div className="form-list-name" onClick={(event) => handlePaymentActionOpen(event)} style={{ color: '#0e8ff1', cursor: 'pointer' }}>
                        {paymentMode}

                    </div>

                    <Popover open={paymentActionOpen} anchorEl={anchorPaymentActionEl} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }} targetOrigin={{ horizontal: 'left', vertical: 'top' }} onClose={() => handlePaymentActionClose()} >
                        <div style={{ padding: '10px' }} >
                            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '10px', padding: '5px' }}>
                                <div style={{ placeSelf: 'center', fontWeight: 'bold' }}>Date</div>
                                <div style={{ placeSelf: 'center', fontWeight: 'bold' }}>Amount applied</div>
                            </div>
                            {paymentList.map(m => {
                                return (
                                    <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridColumnGap: '10px', padding: '5px' }}>
                                        <div style={{ placeSelf: 'center' }}>{m.payment_date}</div>
                                        <div style={{ placeSelf: 'center' }}>{tempQuoteObject.currency}{(m.payment_type === 'Refund') ? "(-)" : "(+)"}{formatCurrency(getFloatParam(m.payment_value))}</div>
                                    </div>
                                );
                            })}
                        </div>
                    </Popover>
                </div>
            </div>
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> createPaymentDetails()':" + error);
        }
        return elements;
    }

    const generateIcons = () => {
        let elementIcons = [];

        try {
            let tempQuoteObject = quoteObject;
            let quoteId = tempQuoteObject.hasOwnProperty('quoteid') ? getIntParam(tempQuoteObject.quoteid) : 0;
            let qbQuoteId = tempQuoteObject.hasOwnProperty('qbQuoteId') ? getIntParam(tempQuoteObject.qbQuoteId) : 0;
            let isRoyaltyInvoice = tempQuoteObject.hasOwnProperty('isRoyaltyInvoice') ? getBooleanParam(tempQuoteObject.isRoyaltyInvoice) : false;
            let options = [];
            if (!isEditMode ) {
                options.push({ name: 'edit', label: getLocalizedStrings().label.COMMON.EDIT, icon: '', })
            }
            if (!isEditMode) {
                options.push({ name: 'send', label: getLocalizedStrings().label.COMMON.SEND_EMAIL });
            }

            if (quoteId > 0) {
            }

            if (!isEditMode) {
                if (!isRoyaltyInvoice) {
                    options.push({ name: 'copy', label: getLocalizedStrings().label.COMMON.COPY });
                }
                if (qbQuoteId === 0) {
                    options.push({ name: 'print', label: getLocalizedStrings().label.COMMON.PRINT });
                } else {
                    options.push({ name: 'print', label: getLocalizedStrings().label.COMMON.PRINT });
                }

            }

            let iconStyle = { fontSize: '18px', color: '#717171', cursor: 'pointer', opacity: 1 };

            options.forEach((opt) => {
                let icons = null;
                icons = opt.name !== 'copy' ? <i className="material-icons" style={iconStyle}>{opt.name}</i> : <i className="far fa-copy" style={iconStyle}></i>
                elementIcons.push(
                    <div title={opt.label}
                        style={{ ...styles.togButton, padding: '5px', backgroundColor: '#ffffff', border: '1px solid rgb(224,224,224)', flex: 'right', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}
                        onClick={() => handleActions(opt.name)}>
                        {icons}
                    </div>
                )
            })
            elementIcons = <div style={{ marginBottom: 10, marginLeft: '25px' }}>
                {elementIcons}
            </div>
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> generateIcons()':" + error);
        }
        return elementIcons;
    }

    const generateActionsElement = () => {
        let actionElements = null;

        try {
            let actionsList = [];
            let tempQuoteObject = quoteObject;
            let isQB = appState.me.is_qbsetup;
            let xeroIntegration = appState.me.hasOwnProperty('xero_integration') ? appState.me.xero_integration : null;
            let isXero = null;
            let xeroIntegrationType = null;
            let isXeroInvoice = null;
            let isXeroInvoiceAndPayments = null;
            if (isValidParam(xeroIntegration)) {
                isXero = xeroIntegration.is_xero;
                xeroIntegrationType = xeroIntegration.xero_integration_type;
                isXeroInvoice = xeroIntegration.is_xero_invoice;
                isXeroInvoiceAndPayments = xeroIntegration.is_xero_payment;
            }
            if (tempQuoteObject.header === 'Invoice') {
                if (tempQuoteObject.status === "Partially Paid" || tempQuoteObject.status === "Approved" || tempQuoteObject.status === "New") {
                    actionsList.push({ name: 'addPayment', value: 'addPayment', label: getLocalizedStrings().label.COMMON['Add Payment'] });
                    if (isQB) {
                        actionsList.push({ name: 'addQuickbooks', value: 'addQuickbooks', label: getLocalizedStrings().label.COMMON.TO_QUICKBOOKS });
                    } else if (isXero && xeroIntegrationType != null && xeroIntegrationType != undefined
                        && xeroIntegrationType === "MANUALLY" && (isXeroInvoice || isXeroInvoiceAndPayments)) {
                        actionsList.push({ name: 'addXero', value: 'addXero', label: getLocalizedStrings().label.COMMON.TO_XERO });
                    }
                } else if (tempQuoteObject.status === 'Paid') {
                    if (tempQuoteObject.balance_due > 0) {
                        actionsList.push({ name: 'addPayment', value: 'addPayment', label: getLocalizedStrings().label.COMMON['Add Payment'] });
                    }
                    if (isQB) {
                        actionsList.push({ name: 'addQuickbooks', value: 'addQuickbooks', label: getLocalizedStrings().label.COMMON.TO_QUICKBOOKS });
                    } else if (isXero && xeroIntegrationType != null && xeroIntegrationType != undefined
                        && xeroIntegrationType === "MANUALLY" && (isXeroInvoice || isXeroInvoiceAndPayments)) {
                        actionsList.push({ name: 'addXero', value: 'addXero', label: getLocalizedStrings().label.COMMON.TO_XERO });
                    }
                } else if (tempQuoteObject.balance_due > 0) {  // 'Sent to QB'
                    actionsList.push({ name: 'addPayment', value: 'addPayment', label: getLocalizedStrings().label.COMMON['Add Payment'] });
                    if (isQB) {
                        actionsList.push({ name: 'addQuickbooks', value: 'addQuickbooks', label: getLocalizedStrings().label.COMMON.TO_QUICKBOOKS });
                    } else if (isXero && xeroIntegrationType != null && xeroIntegrationType != undefined
                        && xeroIntegrationType === "MANUALLY" && (isXeroInvoice || isXeroInvoiceAndPayments)) {
                        actionsList.push({ name: 'addXero', value: 'addXero', label: getLocalizedStrings().label.COMMON.TO_XERO });
                    }
                }
            } else {
                if (tempQuoteObject.status === 'New' || tempQuoteObject.status === 'Sent' || tempQuoteObject.status === 'Approval Requested') {

                    actionsList.push({ name: 'Request Approval', value: 'Request Approval', label: 'Request Approval' });
                    actionsList.push({ name: 'convertToInvoice', value: 'convertToInvoice', label: getLocalizedStrings().label.COMMON.CONVERT_INVOICE });
                    actionsList.push({ name: 'addJobs', value: 'addJobs', label: getLocalizedStrings().label.COMMON.ADD_JOBS });
                } else if (quoteObject.status === 'Approved') {
                    actionsList.push({ name: 'convertToInvoice', value: 'convertToInvoice', label: getLocalizedStrings().label.COMMON.CONVERT_INVOICE });
                    actionsList.push({ name: 'addJobs', value: 'addJobs', label: getLocalizedStrings().label.COMMON.ADD_JOBS });
                }
            }
            let buttonStyle = {
                marginTop: '0px',
                width: '100%'
            };

            actionElements =
                <div style={{ width: '100%' }}>
                    {
                        actionsList.map(m =>
                            <Button
                                key={'key-' + m.value}
                                size="large"
                                style={{
                                    ...styles.primaryButton,
                                    width: '100%',
                                    marginTop: '20px',
                                    height: '38px',
                                    paddingTop: '15px',
                                    paddingBottom: '15px',
                                    marginLeft: '16%',
                                }}
                                onClick={() => handleMoreActions(m.value)}
                            >
                                {m.label}
                            </Button>
                        )
                    }
                </div>

        } catch (error) {
            console.error("Error in 'quoteComponent.js -> generateActionsElement()':" + error);
        }
        return actionElements;
    }

    const openMorePopOver = (event) => {
        setIsOpenMore(true);
    }

    const handleMoreActions = (value, event) => {
        setIsOpenMore(false);
        handleActions(value);

    }

    const handleMoreActionClose = () => {
        setIsOpenMore(false);
    }

    const getCompanyInfo = () => {
        let tempQuoteObject = quoteObject;
        let isValidTenantInfo = quoteObject.hasOwnProperty('tenantinfo') ? true : false;
        let tenantinfo = getObjectParam(tempQuoteObject.tenantinfo);

        return (
            <div style={{ display: 'grid', gridRowGap: '6px', width: '75%', paddingLeft: '6em' }}>
                {isValidTenantInfo ? Object.keys(getObjectParam(tenantinfo)).map(key => {
                    let value = tenantinfo[key];
                    if (value) {
                        if (key === 'company') {
                            return (<div style={{ fontSize: '14px' }} title={value}>{value}</div>);
                        } else {
                            return (<div style={{ fontSize: '14px', whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis' }} title={value} >{value}</div>);
                        }
                    }
                }) :
                    <div style={{ paddingTop: '14px' }}>
                        <div style={{ cursor: 'pointer', color: '#337ab7', textDecoration: 'underline' }} onClick={() => openCompanyProfileTab()}>Enter Company Information</div>
                    </div>
                }
            </div>
        )
    }

    const openCompanyProfileTab = () => {
        try {
            let tab = {
                item: constants.SETTING,
                label: getLocalizedStrings().label.COMMON.SETTINGS,
                object: 'company-profile',
                imgName: 'fas fa-cog',
                url: '/setting/module',
                type: TYPE_LIST_VIEW,
            };
            tab.info = { filter: { id: 0, name: getLocalizedStrings().label.CUSTOMIZATION_SETTING.COMPANY_PROFILE, type: '' } };
            tab.info.selectedItem = { id: 0, name: 'company-profile', label: getLocalizedStrings().label.CUSTOMIZATION_SETTING.COMPANY_PROFILE, type: "menu-item", object: 'company-profile' };
            addTab(tab, true);
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> QuoteEdit -> openCompanyProfileTab()':" + error);
        }
    }

    const getCustomerInfo = (fieldName, labelName) => {
        let infoElements = null;
        let customerDetails = quoteObject.customerinfo;
        let val = quoteObject.customerinfo.company;
        let details = customerDetails.hasOwnProperty(fieldName) ? getStringParam(customerDetails[fieldName]) : null;
        try {
            infoElements = <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                <TextField
                    label={labelName}
                    id="outlined-multiline-static"
                    variant="outlined"
                    value={details}
                    title={details}
                    onChange={(e) => handleCutosmerDeatilsChange(fieldName, e.target.value)}
                    style={{ backgroundColor: '#fff', height: '42px', borderRadius: '3px' }}
                    //underlineshow={false}
                    autoComplete="new-password"
                    inputprops={{
                        style: { height: '4px', fontSize: '15px' }
                    }}
                    type='text'
                    size="small"
                />
            </FormControl>
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> QuoteEdit -> getCustomerInfo()':" + error);
        }
        return infoElements;
    }

    const getCategoryInfo = (labelName) => {
        let infoElements = null;
        let category = quoteObject.category;
        try {
            infoElements = <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                <TextField
                    label={labelName}
                    id="outlined-multiline-static"
                    variant="outlined"
                    value={category}
                    title={category}
                    //onChange={(e) => handleCutosmerDeatilsChange(fieldName,e.target.value)}
                    style={{ backgroundColor: '#fff', height: '42px', borderRadius: '3px' }}
                    autoComplete="new-password"
                    inputprops={{
                        style: { height: '4px', fontSize: '15px' }
                    }}
                    type='text'
                    size="small"
                    readOnly={true}
                />
            </FormControl>
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> QuoteEdit -> getCategoryInfo()':" + error);
        }
        return infoElements;
    }

    const handleCutosmerDeatilsChange = (fieldName, value) => {
        let customerDteails = quoteObject.customerinfo;
        customerDteails[fieldName] = value;
        let tempQuoteObject = quoteObject;
        tempQuoteObject.customerinfo = customerDteails;
        setQuoteObject((prev) => ({ ...prev, ...tempQuoteObject }));
    }

    const handleHeaderChange = (name, event, objValue) => {
        let tempQuoteObject = quoteObject;
        if (name === 'header') {
            tempQuoteObject[name] = objValue.value
        }
        setQuoteObject((prev) => ({ ...prev, ...tempQuoteObject }));

    }

    const handleCategoryChange = (name, event, objValue) => {
        const { id, projectId } = appState.me;
        let tempQuoteObject = quoteObject;
        if (name === 'category') {
            tempQuoteObject[name] = objValue?.value ?? '';
         }
        setQuoteObject((prev) => ({ ...prev, ...tempQuoteObject }));
    }

    if (mounted) {
        let template = generateQuoteTemplate();
        let tempQuoteObject = _.cloneDeep(quoteObject);
        if (totalAmountState && !isEditMode && tempQuoteObject.balance_due && (Number(totalAmountState) !== Number(tempQuoteObject.balance_due))) {
            setTotalAmountState(Number(tempQuoteObject.balance_due))

        }
        let isTenant = appState.me.is_tenant;
        let qbQuoteId = tempQuoteObject.hasOwnProperty('qbQuoteId') ? getIntParam(tempQuoteObject.qbQuoteId) : 0;
        let isShowQuote = tempQuoteObject.hasOwnProperty('tenantinfo') ? true : isTenant ? true : false;
        if (getStringParam(tempQuoteObject.status) === 'Deleted') {
            return <NoRecords object={object} />
        }
        let quoteNameList = getArrayParam(tempQuoteObject.quotetype).map(m => { return { value: m, label: m } });

        let categoryList = getArrayParam(tempQuoteObject.categoryList).map(m => { return { value: m, label: m } });

        let fisrtObj = {};
        fisrtObj.value = '-9999';
        fisrtObj.label = "None";
        categoryList.unshift(fisrtObj);

        let tdStyle = { fontSize: '30px', placeSelf: 'center start', maxWidth: '100%', fontWeight: 'bold' };
        let rowMiddleStyle = { display: 'grid', gridTemplateColumns: '25% 1fr', gridColumnGap: '15px', height: '52px' }

        if (isShowQuote) {
            let paymentAmount = getFloatParam(tempQuoteObject.paymentAmount);
            let subtotal = '';
            let lineiteminfo = getArrayParam(quoteObject.lineiteminfo);
            let productRowList = ['name', 'desc', 'qty', 'uprice', 'amount'];
            lineiteminfo.forEach((item, index) => {
                if (item.name != '') {
                    productRowList.forEach(key => {
                        let value = item[key];
                        if (key == 'amount') {
                            value = Number(item['qty']) * Number(item['uprice']);
                            subtotal = Number(subtotal) + Number(value);
                        }
                        if (key == 'amount' || key == 'uprice') {
                            value = (value != '' || value != 0) ? '$' + formatCurrency(value) : '';
                        }
                    });
                }
            });
            subtotal = getFloatParam(subtotal);
            let grandtotal = getFloatParam((Number(subtotal) + Number(tempQuoteObject.taskrate)) - Number(tempQuoteObject.discount));
            let blanceDue = getFloatParam(Number(grandtotal) - Number(getFloatParam(tempQuoteObject.paymentAmount)));
            subtotal = formatCurrency(subtotal);
            grandtotal = formatCurrency(grandtotal);
            let paymentList = getArrayParam(tempQuoteObject.paymentList);
            let refundedPaymentList = paymentList.filter(u => { return u.payment_type === "Refund" });
            let refundedAmount = 0;
            if (refundedPaymentList.length > 0) {
                refundedPaymentList.map((items, Index) => {
                    if (Index === 0) {
                        refundedAmount = (Number(items.payment_value));
                    } else {
                        refundedAmount = (refundedAmount + (Number(items.payment_value)));
                    }
                })
                refundedAmount = getFloatParam(refundedAmount);
            }
            if (refundedAmount > 0) {
                blanceDue = getFloatParam(Number(blanceDue) + Number(refundedAmount));
            }
            blanceDue = Number((blanceDue));
            if (isEditMode) {
                blanceDue = Number((dueBal));
            }
            let drHeight = window.innerHeight - 150;
            return (
                <div style={{ display: 'grid', gridTemplateColumns: 'minmax(1000px, 1fr)', justifyContent: 'center', overflowY: 'auto', height: drHeight }}>
                    <div style={{ marginLeft: '130px', marginTop: '20px', marginBottom: '20px' }}>
                        <div style={{ height: '5px', paddingTop: '5px' }}>
                            {isLoading &&
                                <div style={{ textAlign: 'center' }}>
                                    <span className="loader"><span className="loader-box"></span><span className="loader-box"></span><span className="loader-box"></span></span>
                                </div>}
                        </div>

                        <div style={{ display: 'grid', gridTemplateColumns: '59% 150px auto', paddingLeft: '3%', marginBottom: '25px', columnGap: '1%', }}>
                            <div style={{ display: 'grid', gridTemplateColumns: '400px auto', alignItems: 'center' }}>
                                <div className="" style={tdStyle}>{getStringParam(tempQuoteObject.header).toUpperCase()}
                                    <span title={tempQuoteObject.quoteno} > #{tempQuoteObject.quoteno}</span>
                                </div>
                                <div style={{ paddingTop: '5px', marginLeft: '-25px', }}>{getQuoteStatusElements()}</div>
                            </div>
                            <div></div>
                            <div style={{ paddingLeft: '-1em' }}>
                                {generateIcons()}
                            </div>
                        </div>
                        <div style={{ display: 'grid', gridTemplateColumns: '160px 59% auto', paddingLeft: '2%', gridColumnGap: '0%', border: '0px solid red' }}>
                            <div>
                                <img
                                    style={{ width: 'auto', maxWidth: '160px', height: 'auto', maxHeight: '130px' }}
                                    src={tempQuoteObject.logourl} >
                                </img>
                            </div>
                            <div> {getCompanyInfo()} </div>
                            <div style={{ display: tempQuoteObject.status === 'Approved' ? '' : 'grid', gridTemplateRows: '20px 100px auto', gridRowGap: '3%', marginLeft: '55px' }}>
                                {tempQuoteObject.status == 'Approved' &&
                                    <div>{getQuoteStatusElementsForApproved()}</div>
                                }
                                {<div style={{ marginTop: tempQuoteObject.status == 'Approved' ? '15px' : '25px', }}> Balance Due</div>}
                                {
                                    <div style={{ fontSize: '40px', marginTop: tempQuoteObject.status === 'Approved' ? '15px' : '25px', fontWeight: 'bold', gridTemplateRows: quoteObject.status == 'Approved' ? '' : '20px 100px auto' }}>{getStringParam(quoteObject.currency) + ' ' + formatCurrency(totalAmountState === null || ((Number(totalAmountState) != Number(quoteObject.balance_due) && !isEditMode)) ? blanceDue : blanceDue)}</div>
                                }


                                {paymentAmount > 0 && createPaymentDetails()}
                            </div>
                        </div>

                        <div style={{ display: 'grid', gridAutoRows: 'auto', gridTemplateColumns: '75% auto', width: '95%', gridColumnGap: '4%', gridRowGap: '15px' }}>
                            <div style={{ padding: '0 15' }}>
                                <div style={{ display: 'grid', gridTemplateColumns: '45% 45% ', gridColumnGap: '4.5%', rowGap: 15, marginBottom: 15 }}>

                                    {getIntParam(tempQuoteObject.quoteid) === 0 && isEditMode &&
                                        <div>
                                            <FormControl variant="outlined" style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                                <Autocomplete
                                                    key={`select-field-quote`}
                                                    defaultValue={quoteNameList.find(v => v.value === tempQuoteObject.header)}
                                                    options={quoteNameList}
                                                    onChange={(event, value) => handleHeaderChange('header', event, value)}
                                                    getOptionLabel={(option) => option.label}
                                                    renderTags={(value, getTagProps) =>
                                                        value.map((option, index) => (
                                                            <Chip
                                                                label={option.label}
                                                                title={option.label}
                                                                {...getTagProps({ index })}
                                                            />
                                                        ))
                                                    }
                                                    disableClearable={false}
                                                    renderInput={(params) => <TextField {...params}
                                                        label={"Type"}
                                                        variant="outlined" margin="dense"
                                                        size='small' />
                                                    }
                                                />
                                            </FormControl>
                                        </div>}
                                    {isEditMode &&
                                        <div> <FormControl variant="outlined" style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                            <Autocomplete
                                                key={`select-category`}
                                                value={categoryList.find(v => {
                                                    return v.value === quoteObject?.category})?.label || null}
                                                options={categoryList}
                                                onChange={(event, value) => handleCategoryChange('category', event, value)}
                                                getOptionLabel={(option) =>
                                                    typeof option === "string" ? option : option.label
                                                }
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => (
                                                        <Chip
                                                            label={option.label}
                                                            title={option.label}
                                                            {...getTagProps({ index })}
                                                        />
                                                    ))
                                                }
                                                disableClearable={false}
                                                renderInput={(params) => <TextField {...params}
                                                    label={"Category"}
                                                    variant="outlined" margin="dense"
                                                    size='small' />
                                                }
                                            />
                                        </FormControl>
                                        </div>}
                                    {getIntParam(tempQuoteObject.quoteid) > 0 && isEditMode && <div></div>}
                                    {isEditMode && <div>{getCustomerInfo('company', 'Customer')}</div>}
                                    {isEditMode && <div>{getCustomerInfo('email', 'Email')}</div>}
                                </div>
                            </div>
                            <div >
                                <div style={{ display: 'grid', gridRowGap: '10%' }}>
                                    {isEditMode && generateHeaderbuttons()}
                                </div>
                                {/* <div style={{ width: '12em' }}>
                                    {!isEditMode && getIntParam(quoteObject.quoteid) > 0 && generateActionsElement()}
                                </div> */}
                            </div>
                        </div>



                        <div style={{ display: 'grid', width: '100%', }}>
                            <div>
                                <div style={{ padding: '0 15px', width: '95%', display: 'grid', border: '0px solid red' }}>
                                    {(!isEditMode) ?
                                        <div style={{ display: 'grid', gridTemplateColumns: '1fr 25%', border: '0px solid green' }}>
                                            <div id={'quote-id'} dangerouslySetInnerHTML={{ __html: (template) }} />
                                            <div style={{ width: '12em', marginLeft: '50px' }}>{getIntParam(quoteObject.quoteid) > 0 && generateActionsElement()}</div>
                                        </div>
                                        :
                                        <QuoteEdit handlePriceChange={(tempQuoteObject) => {
                                            setDueBal(tempQuoteObject);
                                            if (totalAmountState !== tempQuoteObject) {
                                                setTotalAmountState(tempQuoteObject);
                                            }
                                        }
                                        } ref={quoteEditRefs} quoteObject={tempQuoteObject} getActions={generateActionsElement} getHeader={generateHeaderbuttons} funcType={funcType} />


                                    }
                                </div>
                            </div>

                            <div>
                            </div>

                        </div>
                    </div >
                </div>
            );
        } else {
            let msg = `Please set up your company profile before adding quotes. Please contact your CRM administrator to set up the profile.`;
            return (
                <div style={{ transform: 'translateY(30%)', padding: '0px 20%' }}>
                    <div className="panel panel-danger">
                        <div className="panel-heading">Alert</div>
                        <div className="panel-body" style={{ height: '30%' }}>
                            <h4>{msg}</h4>
                        </div>
                    </div>
                </div>
            )
        }
    } else {
        let contentHeight = window.innerHeight - 240;
        let top = (contentHeight - 10) / 2
        return (
            <div style={{ width: '100%', height: contentHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '48%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }
}

const QuoteEdit = forwardRef(({ handlePriceChange, quoteObject, getActions, getHeader, funcType }, ref) => {

    const dispatch = useDispatch();
    const [quoteObjectData, setQuoteObjectData] = useState(quoteObject);
    const [actionsList, setActionsList] = useState({});
    const [autoCompleteObj, setAutoCompleteObj] = useState({});
    const [customerBillToDetailsFields, setCustomerBillToDetailsFields] = useState(quoteObject.customerinfo);
    const [isMounted, setIsMounted] = useState(false);
    const [quoteNameList, setQuoteNameList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [category, setCategory] = useState("None");
    const [productinfoList, setProductinfoList] = useState([]);
    const [termsList, setTermsList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [isCcOpen, setIsCcOpen] = useState(false);


    const app = useSelector((state) => state.app);
    const sfForm = useSelector((state) => state.sfForm);
    const quotation = useSelector((state) => state.quotation);
    const tab = useSelector((state) => state.tab);



    useEffect(() => {
        processData();

        const current = new Date();
        let date = `${("0" + (current.getMonth() + 1)).slice(-2)}/${("0" + current.getDate()).slice(-2)}/${("0" + current.getFullYear()).slice(-2)}`;

        if (funcType == 'copy') {
            let tempQuoteObject = quoteObjectData;

            // tempQuoteObject.quotedate = date;
            setQuoteObjectData(tempQuoteObject);
            let validate;
            if (quoteObject.terms == '') {
                validate = moment(date, "MM-DD-YY").add(10, 'days');
            }
            else if (quoteObject.terms == 'Due on receipt') {
                validate = moment(date, "MM-DD-YY").add(0, 'days');
            }
            else if (quoteObject.terms == 'Net 15') {
                validate = moment(date, "MM-DD-YY").add(15, 'days');
            }
            else if (quoteObject.terms == 'Net 30') {
                validate = moment(date, "MM-DD-YY").add(30, 'days');
            }
            else if (quoteObject.terms == 'Net 60') {
                validate = moment(date, "MM-DD-YY").add(60, 'days');
            }
            if (quoteObject.terms == 'Custom') {
                validate = moment(date, "MM-DD-YY").add(10, 'days');
            }
            if (funcType !== 'copy') {
                let temp = quoteObjectData;
                temp.quotevalid = validate;
                setQuoteObjectData(temp);
            }
        }
    }, [])



    //     const processData = () => {
    //         let tempquoteObject = getObjectParam(quoteObjectData);
    //         let lineiteminfo = getArrayParam(tempquoteObject.lineiteminfo);
    //         let customerinfo = getObjectParam(tempquoteObject.customerinfo);
    //         try {
    //             let activeTabInfo = getObjectParam(getActiveTabInfo());
    //             let aTRecordId = getIntParam(activeTabInfo.parentRecordId);
    //             let aTParentObject = activeTabInfo.parentObject;

    //             let _quoteNameList = getArrayParam(tempquoteObject.quotetype).map(m => { return { value: m, label: m } });

    //             let _productinfoList = getArrayParam(tempquoteObject.productinfo).map(m => { return { value: m.name, label: m.name, data: m } });
    //             let newOpt = { desc: "", name: 'Add a new product', productid: "-99", qty: "0", tax: "No", uprice: "0" };
    //             _productinfoList.push({ value: 'Add a new product', label: 'Add a new product', data: newOpt });

    //             let _termsList = getArrayParam(tempquoteObject.termsList).map((obj) => { return { value: obj.name, label: obj.name, key: obj.days } });

    //             let _actionsList = { taskRateAction: { isEdit: false }, discountAction: { isEdit: false } };
    //             let _userList = getArrayParam(tempquoteObject.userList).map((obj) => { return { value: obj.value, label: obj.value, key: obj.id } });
    // setIsMounted(true)
    //             setQuoteNameList(_quoteNameList);
    //             setProductinfoList(_productinfoList);
    //             setActionsList(_actionsList);
    // setTermsList(_termsList);
    // setUserList(_userList)

    //             let subtotal = 0;
    //             lineiteminfo.forEach(e => {
    //                 subtotal = Number(subtotal) + (Number(e.qty) * Number(e.uprice));
    //             });
    //             let taskratePerc = (Number(tempquoteObject.taskrate) * 100) / subtotal;
    // let tempObj= quoteObjectData;
    //             tempObj.taskratePerc = getFloatParam(taskratePerc);
    // setQuoteObjectData(tempObj);

    //         } catch (error) {
    //             console.error("Error in ' processData()':" + error);
    //         }
    //     }

    const processData = () => {
        let tempQuoteObject = getObjectParam(quoteObjectData);
        let lineiteminfo = getArrayParam(tempQuoteObject.lineiteminfo);
        let customerinfo = getObjectParam(tempQuoteObject.customerinfo);
        try {
            let activeTabInfo = getObjectParam(getActiveTabInfo());
            let aTRecordId = getIntParam(activeTabInfo.parentRecordId);
            let aTParentObject = activeTabInfo.parentObject;

            let _quoteNameList = getArrayParam(tempQuoteObject.quotetype).map(m => { return { value: m, label: m } });

            let _categoryList = getArrayParam(tempQuoteObject.category).map(m => { return { value: m, label: m } });

            let _productinfoList = getArrayParam(tempQuoteObject.productinfo).map(m => { return { value: m.name, label: m.name, data: m } });
            let newOpt = { desc: "", name: 'Add a new product', productid: "-99", qty: "0", tax: "No", uprice: "0" };
            _productinfoList.push({ value: 'Add a new product', label: 'Add a new product', data: newOpt });

            let _termsList = getArrayParam(tempQuoteObject.termsList).map((obj) => { return { value: obj.name, label: obj.name, key: obj.days } });

            let _actionsList = { taskRateAction: { isEdit: false }, discountAction: { isEdit: false } };
            let _userList = getArrayParam(tempQuoteObject.userList).map((obj) => { return { value: obj.value, label: obj.value, key: obj.id } });

            setIsMounted(true);
            setQuoteNameList(_quoteNameList);
            setCategoryList(_categoryList);
            setProductinfoList(_productinfoList);
            setActionsList(_actionsList);
            setTermsList(_termsList);
            setUserList(_userList);
            let subtotal = 0;
            lineiteminfo.forEach(e => {
                subtotal = Number(subtotal) + (Number(e.qty) * Number(e.uprice));
            });
            let _taskratePerc = (Number(quoteObject.taskrate) * 100) / (subtotal - Number(quoteObject?.discount));
            tempQuoteObject.taskratePerc = getFloatParam(_taskratePerc);
            setQuoteObjectData({ ...tempQuoteObject });
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> QuoteEdit -> processData()':" + error);
        }
    }
    const handleAction = (event, name, type, objValue) => {
        let isUpdate = true;
        try {
            let value = isValidParam(event) && isValidParam(event.currentTarget) ? event.currentTarget.value : '';
            if (value != '') {
                if (name == 'discount' || name == 'taskratePerc') {
                    value = value.replace(/[^\d.-]/g, '');
                    let regExp = name == 'discount' ? /^\d+(?:\.\d{0,2})?$/ : name == 'taskratePerc' ? /^\d{0,2}(?:\.\d{0,2})?$/ : null;
                    isUpdate = regExp != null ? regExp.test(value) : true;
                } else if (type == 'customerinfo' && name == 'phone') {
                    let regExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
                    isUpdate = regExp.test(value);
                }
            }
            if (isUpdate) {
                if (type === 'customerinfo') {
                    let customerinfo = quoteObjectData.customerinfo;
                    customerinfo.name = value;
                    let temp = quoteObjectData;
                    temp.customerinfo = customerinfo;
                    setQuoteObjectData({ ...temp });
                } else if (quoteObjectData.hasOwnProperty(name)) {
                    if (name === 'header') {
                        value = objValue.value;
                    }
                    if (name === 'terms') {
                        value = objValue.value;
                        let temp = quoteObjectData;
                        temp.terms = value;
                        setQuoteObjectData({ ...temp })
                        if (value != 'Custom') {
                            let key = objValue.key;
                            let quoteValidDateObj = getStringParam(quoteObjectData.quotedate) ? moment(quoteObjectData.quotedate).add(key, 'days') : moment(new Date()).add(key, 'days');
                            quoteValidDateObj = quoteValidDateObj.format(dateFormat[app.me.date_format]);
                            quoteObjectData['quotevalid'] = quoteValidDateObj;
                        }

                    }
                    if (name === 'salesRep') {
                        value = objValue.value;
                        let temp = quoteObjectData;
                        temp.salesRep = value;
                        setQuoteObjectData({ ...temp })
                    }

                    if (name === 'notetext') {
                        // value = objValue.value;
                        let temp = quoteObjectData;
                        temp.notetext = value;
                        setQuoteObjectData({ ...temp })
                    }
                    if (name === 'taskratePerc') {
                        // value = objValue.value;
                        let temp = quoteObjectData;
                        temp.taskratePerc = value;
                        setQuoteObjectData({ ...temp })
                    }
                    if (name === 'discount') {
                        // value = objValue.value;
                        let temp = quoteObjectData;
                        temp.discount = value;
                        setQuoteObjectData({ ...temp })
                    }
                    let tempobj = quoteObjectData;
                    tempobj.name = value;
                    setQuoteObjectData({ ...tempobj })
                }

                handlePriceChange(quoteObjectData)

            }
        } catch (error) {
            console.error("Error in ' handleAction()':" + error);
        }
    }

    const handleEditAction = (isEdit, type) => {
        try {
            let _actionsList = actionsList;
            if (type == 'taskrate') {
                let taskRateAction = getObjectParam(_actionsList.taskRateAction);
                taskRateAction.isEdit = isEdit;
                _actionsList.taskRateAction = taskRateAction;
                setActionsList({ ..._actionsList })
            } else if (type == 'discount') {
                let discountAction = getObjectParam(_actionsList.discountAction);
                discountAction.isEdit = isEdit;
                _actionsList.discountAction = discountAction;
                setActionsList({ ..._actionsList })
            }
        } catch (error) {
            console.error("Error in ' handleEditAction()':" + error);
        }
    }

    const handleFlatPickrDateChange = (date, type) => {
        try {
            let dateValue = new Date(date);
            let selectedDate = new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate(), dateValue.getHours(), dateValue.getMinutes(), dateValue.getSeconds());
            const momentDate = moment(selectedDate);

            let value = momentDate.format(dateFormat[app.me.date_format]);
            let _quoteObject = quoteObjectData;
            if (type == 'quoteDate') {
                _quoteObject.quotedate = value;
            } else if (type == 'quoteValid') {
                _quoteObject.quotevalid = value;
            }
            setQuoteObjectData(_quoteObject);
        } catch (error) {
            console.error("Error in  handleFlatPickrDateChange()':" + error);
        }
    }

    const onChangeProduct = (event, name, rowId, type) => {
        let _quoteObject = quoteObjectData;
        let lineiteminfo = getArrayParam(_quoteObject.lineiteminfo);
        let _actionsList = actionsList;
        let value = event.target.value;
        if (type == 'field') {
            let isUpdate = true;
            if (value != '' && (name == 'qty' || name == 'uprice')) {
                //value = value.replace(/[^\d.-]/g, '');
                // let regExp = name == 'qty' ? /^\d{0,4}(?:\.\d{0,2})?$/ : /^\d+(?:\.\d{0,2})?$/;
                let regExp = name == 'qty' ? /^\d{0,6}(?:\.\d{0,2})?$/ : /^-?\d*(?:\.\d{0,2})?$/;
                isUpdate = regExp.test(value);
            }
            if (isUpdate) {
                lineiteminfo = lineiteminfo.map((m, index) => {
                    return (index == rowId) ? { ...m, [name]: value } : m;
                });
                _quoteObject.lineiteminfo = lineiteminfo;
                setQuoteObjectData({ ..._quoteObject });
            }
        } else if (type === 'select') {
            let _productinfoList = getObjectParam(productinfoList);
            _productinfoList = _productinfoList.find(f => f.data.productid == value);

            if (_productinfoList) {
                value = getStringParam(_productinfoList.value);

                let productDataList = getObjectParam(_productinfoList.data);
                //setProductinfoList({..._productinfoList})
                lineiteminfo = lineiteminfo.map((m, index) => {
                    let obj = m;
                    if (index == rowId) {
                        Object.keys(m).forEach(e => {
                            if (e != 'id') {
                                obj = { ...obj, [e]: productDataList[e] };
                            }
                        })
                    }
                    return obj;
                });

                if (lineiteminfo.length > 0 && lineiteminfo.length == rowId + 1) {
                    let obj = {};
                    Object.keys(lineiteminfo[0]).forEach(e => {
                        if (e != 'id') {
                            obj = { ...obj, [e]: '' };
                        }
                    });
                    lineiteminfo.push(obj);

                }
                let temp = quoteObjectData;
                temp.lineiteminfo = lineiteminfo;
                setQuoteObjectData({ ...temp })
            }
        }

    }

    const handleDeleteLineItems = (index, id, event) => {
        try {
            let lineiteminfo = getArrayParam(quoteObjectData.lineiteminfo);
            let deletedLineItemsIds = [];
            if (lineiteminfo.length > 1) {
                lineiteminfo.splice(index, 1);
                let temp = quoteObjectData;
                temp.lineiteminfo = lineiteminfo;
                setQuoteObjectData({ ...temp });
            } else {
                let obj = {};
                Object.keys(lineiteminfo[0]).forEach(e => {
                    if (e != 'id') obj = { ...obj, [e]: '' };
                });
                let temp = quoteObjectData;
                temp.lineiteminfo[0] = obj;
                setQuoteObjectData({ ...temp });
            }

            if (id > 0) {
                deletedLineItemsIds = getArrayParam(quoteObjectData.deletedLineItemsIds);
                deletedLineItemsIds.push({ 'id': id });
                let temObj = quoteObjectData;
                temObj.deletedLineItemsIds = deletedLineItemsIds;
                setQuoteObjectData({ ...temObj })
            }
        } catch (error) {
            console.error("Error in ' handleDeleteLineItems()':" + error);
        }
    }

    //    const getMenuItems = () => {
    //         let objectItems = null;
    //         try {
    //             let objectList = getArrayParam(quoteObjectData);
    //             objectItems = objectList.map((item, index) => {

    //                 return <MenuItem key={index + '-' + item.value} id={item.value} value={getObjectParam(item.data).productid} style={styles.popoverMenuItem} title={item.label} dataset={item.data}>{item.label}</MenuItem>;
    //             });
    //         } catch (error) {
    //             console.error("Error in 'quoteComponent.js -> QuoteEdit -> getMenuItems()':" + error);
    //         }
    //         return objectItems;
    //     }

    const getMenuItems = () => {
        let objectItems = null;
        try {
            let objectList = getArrayParam(productinfoList);
            objectItems = objectList.map((item, index) => {

                return <MenuItem key={index + '-' + item.value} id={item.value} value={getObjectParam(item.data).productid} style={{ ...styles.popoverMenuItem }} title={item.label} dataset={item.data}>{item.label}</MenuItem>;
            });
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> QuoteEdit -> getMenuItems()':" + error);
        }
        return objectItems;
    }
    useEffect(() => {
        let subtotal = 0;
        let _quoteObject = quoteObjectData;
        let lineiteminfo = getArrayParam(_quoteObject.lineiteminfo);
        lineiteminfo.forEach(e => {
            subtotal = Number(subtotal) + (Number(e.qty) * Number(e.uprice));
        });
        let taskrate = ((Number(subtotal) - Number(_quoteObject.discount)) * Number(_quoteObject.taskratePerc)) / 100;
        let temp = quoteObjectData;
        temp.taskrate = getFloatParam(taskrate);
        setQuoteObjectData(temp);
    }, [quoteObjectData])

    const generateProductItems = () => {
        let _quoteObject = quoteObjectData;
        let activeTabInfo = getObjectParam(getActiveTabInfo());
        var isFromRoyalty = false;
        let lineiteminfo = getArrayParam(_quoteObject.lineiteminfo);
        let productRowList = [{ name: 'name', label: 'ITEM' }, { name: 'desc', label: 'DESCRIPTION' }, { name: 'qty', label: 'QTY' },
        { name: 'uprice', label: 'RATE' }, { name: 'amount', label: 'AMOUNT' }, { name: 'delete', label: '' }];
        let subtotal = 0;
        lineiteminfo.forEach(e => {
            subtotal = Number(subtotal) + (Number(e.qty) * Number(e.uprice));
        });
        subtotal = getFloatParam(subtotal);
        let taskrate = ((Number(subtotal) - Number(_quoteObject.discount)) * Number(_quoteObject.taskratePerc)) / 100;
        // let temp = quoteObjectData;
        // temp.taskrate = getFloatParam(taskrate);
        // setQuoteObjectData(temp);
        let grandtotal = getFloatParam((Number(subtotal) + Number(taskrate)) - Number(_quoteObject.discount));
        let balnceDue = getFloatParam(Number(grandtotal) - Number(getFloatParam(_quoteObject.paymentAmount)));

        let symbol = isValidParam(_quoteObject.currency) && _quoteObject.currency != '' ? _quoteObject.currency : '$';
        let paymentAmount = Number(getFloatParam(_quoteObject.paymentAmount));
        let paymentList = getArrayParam(_quoteObject.paymentList);
        let refundedPaymentList = paymentList.filter(u => { return u.payment_type == "Refund" });
        let refundedAmount = 0;
        if (refundedPaymentList.length > 0) {
            refundedPaymentList.map((items, Index) => {
                if (Index === 0) {
                    refundedAmount = getFloatParam(Number(items.payment_value));
                } else {
                    refundedAmount += getFloatParam(Number(items.payment_value));
                }
            })
        }
        if (refundedAmount > 0) {
            balnceDue = getFloatParam(Number(balnceDue) + Number(refundedAmount));
        }
        handlePriceChange(balnceDue);
        let headerStyle = { border: '1px solid #ddd', height: 'inherit', paddingTop: '6px' };
        let tableGridTemplateColumns = '20% 39% 10% 13% 13% 5%';
        let btmColumnsStyle = { display: 'grid', gridTemplateColumns: '63% 17% 20%', height: '32px' };
        let isDisabled = false;
        if (activeTabInfo.callFor == constants.ROYALTY_OBJECT || getBooleanParam(_quoteObject.isRoyaltyInvoice)) {
            isFromRoyalty = true;
            productRowList = [{ name: 'name', label: 'ROYALTY' }, { name: 'desc', label: 'DESCRIPTION' }, { name: 'qty', label: 'QTY' },
            { name: 'uprice', label: 'RATE' }, { name: 'amount', label: 'AMOUNT' }, { name: 'delete', label: '' }];
        }
        if (refundedAmount > 0) {
            balnceDue = getFloatParam(Number(balnceDue) + Number(refundedAmount));
        }


        try {
            return (
                <div style={{ width: "100%", textAlign: "center" }} >
                    <div style={{ display: 'grid', gridTemplateColumns: tableGridTemplateColumns, height: '40Px', color: '#333', fontWeight: 'bold' }} >
                        {productRowList.map(obj => {
                            return <div style={headerStyle}>{obj.label}</div>
                        })}
                    </div>
                    {lineiteminfo.map((item, index) => {
                        let editItemList = ['name', 'desc', 'qty', 'uprice'];
                        let lineItemId = getIntParam(item.id);
                        return (
                            <div className="quote-table" key={`line-item-tr-${item.productid}-${index}`} style={{ display: 'grid', gridTemplateColumns: tableGridTemplateColumns, textAlign: 'center', height: '101px' }}>
                                {productRowList.map(obj => {
                                    let key = obj.name;
                                    if ((activeTabInfo.callFor == constants.ROYALTY_OBJECT || getBooleanParam(_quoteObject.isRoyaltyInvoice)) && (key == 'name' || key == 'qty' || key == 'uprice')) {
                                        isDisabled = true;
                                    } else {
                                        isDisabled = false;
                                    }
                                    let components = null;
                                    let value = item[key];
                                    let pId = item['productid'];
                                    if (key == 'amount') {
                                        value = Number(item['qty']) * Number(item['uprice']);
                                        value = value != 0 ? value : '';
                                    }
                                    if (editItemList.includes(key)) {
                                        if (key == 'name' && pId != "-99" && pId != "-999" && pId != "-9999") {
                                            components =
                                                <div key={`line-item-td-${key}-${index}`} style={{ height: '100%', padding: '2px 2px' }}>
                                                    <FormControl variant="outlined" style={{ width: '100%' }}>
                                                        <InputLabel id="sf-quotecomponent-simple-select-outlined-label" className="sf-quotecomponent"></InputLabel>
                                                        <SelectField
                                                            placeholder='Select Item'
                                                            key={`product-list-${key}-${index}`}
                                                            value={pId}
                                                            title={value}
                                                            name={'product-' + key}
                                                            style={{ height: '95px' }}
                                                            onChange={(event) => onChangeProduct(event, key, index, 'select')}
                                                        //disabled={isDisabled}
                                                        >
                                                            {getMenuItems()}
                                                        </SelectField>
                                                    </FormControl>
                                                </div>
                                        } else {
                                            components =
                                                <div key={`line-item-td-${key}-${index}`} style={{ height: '95px', padding: '2px 2px' }}>
                                                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                                        <TextField
                                                            variant="outlined"
                                                            id={`product-field-${key}`}
                                                            key={`field-${key}-key`}
                                                            maxRows={3}
                                                            type='textarea'
                                                            rows={3}
                                                            value={value == 'Add a new product' ? "" : value}
                                                            title={value}
                                                            onChange={(event) => onChangeProduct(event, key, index, 'field')}
                                                            inputProps={{
                                                                maxLength: key != 'desc' ? 127 : 1024
                                                            }}
                                                            InputProps={{ inputProps: { style: { textAlign: 'center' }, }, maxLength: key == 'name' ? 128 : 1024, style: { textAlign: 'center', height: '95px' } }}
                                                            autoFocus={value == 'Add a new product' ? true : false}
                                                            multiline={key == 'desc' ? true : false}
                                                            autoComplete="new-password"
                                                            readOnly={isDisabled}
                                                            size="small"
                                                        />
                                                    </FormControl>
                                                </div>
                                        }
                                    } else {
                                        let isDelete = true;

                                        if (activeTabInfo.callFor == constants.ROYALTY_OBJECT) {
                                            isDelete = false;
                                        }
                                        if (index + 1 == lineiteminfo.length && item.name == '') {
                                            isDelete = false;
                                        }
                                        if ((key == 'amount' || key == 'uprice') && value != '') {
                                            value = symbol + formatCurrency(getFloatParam(value));
                                        }
                                        if (key == 'delete') {
                                            components =
                                                <div key={`line-item-td-${key}-${index}`} style={{ padding: '5px', height: '100%', lineHeight: '72px', textAlign: 'center' }}>
                                                    {isDelete && <span className='page-action' title='Delete' style={{ color: '#717171', cursor: 'pointer', padding: '5px', fontSize: '16px' }} onClick={(event) => handleDeleteLineItems(index, lineItemId, event)}><i className="fa fa-times"></i></span>}
                                                </div>
                                        } else {
                                            components =
                                                <div key={`line-item-td-${key}-${index}`} title={value} style={{ padding: '2px', height: '100%' }}>
                                                    <div style={{
                                                        border: '1px solid #d5d0d0', borderRadius: '4px', height: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'flex', alignItems: 'center', justifyContent: 'center'
                                                    }}>
                                                        {value}
                                                    </div>
                                                </div>
                                        }
                                    }
                                    return components;
                                })}
                            </div>
                        );
                    })}
                    <div>
                        <div style={{ ...btmColumnsStyle, marginTop: '15px' }}>
                            <div></div>
                            <div className="text-ellipsis" style={{ fontWeight: 'bold', padding: '0px', textAlign: 'right' }}>SUBTOTAL</div>
                            <div title={formatCurrency(subtotal)}>{symbol}{formatCurrency(subtotal)}</div>
                        </div>

                        <div style={btmColumnsStyle}>
                            <div></div>
                            <div className="text-ellipsis" style={{ fontWeight: 'bold', padding: '0px', textAlign: 'right' }}>DISCOUNT</div>
                            <div>
                                {!actionsList.discountAction.isEdit ? <span className='page-row' title={formatCurrency(getFloatParam(_quoteObject.discount))} onClick={() => handleEditAction(true, 'discount')} style={{ padding: '5px 15px', cursor: 'pointer' }}>{symbol}{formatCurrency(getFloatParam(_quoteObject.discount))}</span> :
                                    <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                        <TextField
                                            variant="outlined"
                                            id={`discount`}
                                            key={`discount-key`}
                                            value={_quoteObject.discount}
                                            title={_quoteObject.discount}
                                            onChange={(e, event) => handleAction(e, 'discount', '', event)}
                                            InputProps={{
                                                style: { height: '28px', borderRadius: '0px', width: '30%', placeSelf: 'center' }
                                            }}
                                            onBlur={() => handleEditAction(false, 'discount')}
                                            autoFocus={true}
                                            autoComplete="new-password"
                                            size="small"
                                        /></FormControl>}
                            </div>
                        </div>
                        <div style={btmColumnsStyle}>
                            <div></div>
                            <div style={{ padding: '0px' }}>
                                <div style={{ display: 'flex' }}>
                                    <div className="text-ellipsis" style={{ fontWeight: 'bold', width: '100%', marginLeft: '20px' }}>TAX </div>
                                    {!actionsList.taskRateAction.isEdit ? <div className='page-row' title={getFloatParam(_quoteObject.taskratePerc) + "%"} onClick={() => handleEditAction(true, 'taskrate')} style={{ marginLeft: '10px', cursor: 'pointer', width: '100%', whiteSpace: 'nowrap' }}>{getFloatParam(_quoteObject.taskratePerc)} %</div> :
                                        <div style={{ display: 'flex' }}>
                                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                                <TextField
                                                    variant="outlined"
                                                    id={`task-rate-perc`}
                                                    key={`task-rate-perc-key`}
                                                    value={_quoteObject.taskratePerc}
                                                    title={_quoteObject.taskratePerc}
                                                    onChange={(e, event) => handleAction(e, 'taskratePerc', '', event)}
                                                    InputProps={{
                                                        style: { height: '28px', borderRadius: '0px' }
                                                    }}
                                                    onBlur={() => handleEditAction(false, 'taskrate')}
                                                    autoFocus={true}
                                                    autoComplete="new-password"
                                                    size="small"
                                                /></FormControl>
                                            <div style={{ paddingLeft: '5px' }}>%</div>
                                        </div>}
                                </div>
                            </div>
                            <div><span title={formatCurrency(getFloatParam(taskrate))} >{symbol}{formatCurrency(getFloatParam(taskrate))}</span></div>
                        </div>
                        <div style={btmColumnsStyle}>
                            <div></div>
                            <div className="text-ellipsis" style={{ fontWeight: 'bold', padding: '0px', textAlign: 'right' }}>TOTAL</div>
                            <div title={formatCurrency(grandtotal)}>{symbol}{formatCurrency(grandtotal)}</div>
                        </div>

                        {paymentAmount > 0 &&
                            <div style={btmColumnsStyle}>
                                <div></div>
                                <div className="text-ellipsis" style={{ fontWeight: 'bold', padding: '0px', textAlign: 'right' }}>AMOUNT RECEIVED</div>
                                <div title={formatCurrency(paymentAmount)}>{symbol}{formatCurrency(paymentAmount)}</div>
                            </div>
                        }
                        {refundedAmount > 0 &&
                            <div style={btmColumnsStyle}>
                                <div></div>
                                <div className="text-ellipsis" style={{ fontWeight: 'bold', padding: '0px', textAlign: 'right' }}>REFUNDED</div>
                                <div title={formatCurrency(refundedAmount)}>{symbol}{formatCurrency(refundedAmount)}</div>
                            </div>}
                        <div style={btmColumnsStyle}>
                            <div></div>
                            <div className="text-ellipsis" style={{ fontWeight: 'bold', padding: '0px', textAlign: 'right', paddingBottom: '10px' }}>BALANCE DUE</div>
                            <div title={formatCurrency(balnceDue)}>{symbol}{formatCurrency(balnceDue)}</div>
                        </div>
                    </div>
                </div>
            )
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> QuoteEdit -> generateProductItems()':" + error);
        }
    }

    /*openCompanyProfileTab = () => {
        try {
            let tab = {
                item: constants.SETTING,
                label: getLocalizedStrings().label.COMMON.SETTINGS,
                object: 'company-profile',
                imgName: 'fas fa-cog',
                url: '/setting/module',
                type: TYPE_LIST_VIEW,
            };
            tab.info = { filter: { id: 0, name: getLocalizedStrings().label.CUSTOMIZATION_SETTING.COMPANY_PROFILE, type: '' } };
            tab.info.selectedItem = { id: 0, name: 'company-profile', label: getLocalizedStrings().label.CUSTOMIZATION_SETTING.COMPANY_PROFILE, type: "menu-item", object: 'company-profile' };
            addTab(tab, true);
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> QuoteEdit -> openCompanyProfileTab()':" + error);
        }
    }*/

    const generateBillToDetails = (fieldName, labelName) => {
        let elements = [];
        let customerDetails = customerBillToDetailsFields[fieldName];

        try {
            let billToComponent =
                <FormControl style={{ width: '100%', height: '100%' }} className="test" noValidate autoComplete="off">
                    <TextField
                        id="outlined-multiline-static"
                        multiline
                        rows={7}
                        maxRows={7}
                        value={customerDetails}
                        title={customerDetails}
                        onChange={(e) => handleBillChanges(fieldName, e.target.value)}
                        style={{ width: '100%', backgroundColor: '#fff', height: '91%', }}
                        variant="outlined"
                        // inputStyle={{ color: '#333', fontSize: '15px', fontWeight: 'normal', height:'87%', }}
                        autoComplete="new-password"
                        // textareaStyle={{marginTop: '0px',height:'100%'}}
                        type='textarea'
                        label={labelName}
                        InputLabelProps={{
                            style: {
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                width: '96%',
                            }
                        }}
                        inputprops={{
                            style: { lineHeight: '2', }
                        }}
                        fullWidth={true}
                    />

                </FormControl>

            elements.push(billToComponent);
        } catch (error) {
            console.error("Error in 'quoteComponent.js -> QuoteEdit -> generateBillToDetails()':" + error);
        }
        return elements;
    }

    const handleBillChanges = (fieldName, value) => {
        let customerDteails = customerBillToDetailsFields;
        customerDteails[fieldName] = value;
        setCustomerBillToDetailsFields({ ...customerDteails });
    }

    useImperativeHandle(ref, () => ({ quoteObjectData, customerBillToDetailsFields }));

    let pageHeight = window.innerHeight - 247;
    let top = (pageHeight - 10) / 2;
    if (isMounted) {

        let tenantinfo = getObjectParam(quoteObjectData.tenantinfo);
        let quoteDateObj = getStringParam(quoteObjectData.quotedate) ? moment(quoteObjectData.quotedate) : moment(new Date());
        quoteDateObj = quoteDateObj.format(dateFormat[app.me.date_format]);

        let quoteValidDateObj = getStringParam(quoteObjectData.quotevalid) ? moment(quoteObjectData.quotevalid) : moment(new Date());
        quoteValidDateObj = quoteValidDateObj.format(dateFormat[app.me.date_format]);

        let dueDateLabel = quoteObjectData.header == 'Invoice' ? 'Due Date' : 'Expiration Date';
        // let dateLabel = 'Invoice Date';
        let dateLabel = quoteObjectData.header == 'Invoice' ? 'Invoice Date' : 'Quote Date';

        let HHFormat = new RegExp("HH");
        let datePickerOptions = {
            dateFormat: dateFormatFlatPicker[app.me.date_format],
            minuteIncrement: 1,
            time_24hr: HHFormat.test(app.me.date_format) ? true : false
        };

        let dueDatePickerOptions = {
            dateFormat: dateFormatFlatPicker[app.me.date_format],
            minuteIncrement: 1,
            time_24hr: HHFormat.test(app.me.date_format) ? true : false,
            minDate: "today"
        }
        let tdStyle = { color: '#97989e', fontSize: '16px', placeSelf: 'center start', maxWidth: '100%' };
        let rowMiddleStyle = { display: 'grid', gridTemplateColumns: '25% 1fr', gridColumnGap: '15px', height: '52px' }

        let isValidTenantInfo = quoteObjectData.hasOwnProperty('tenantinfo') ? true : false;
        return (
            <div>
                <div style={{ display: 'grid', gridAutoRows: 'auto', gridTemplateColumns: '45% 45% auto', width: '75%', gridColumnGap: '4%', gridRowGap: '15px' }}>
                    <div>
                        {generateBillToDetails('billto', 'Bill To')}
                    </div>
                    <div>
                        {generateBillToDetails('shipto', 'Ship To')}
                    </div>
                    <div></div>
                    <div style={{ display: 'grid', gridTemplateColumns: '100% auto', gridGap: '5%', marginLeft: '-2px' }}>
                        <div>
                            <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                <Autocomplete
                                    name='preparer'
                                    key={`field-key0-sales-rep`}
                                    defaultValue={userList.find(v => v.value == quoteObject.salesRep)}
                                    options={userList}
                                    onChange={(e, event) => handleAction(e, 'salesRep', '', event)}
                                    getOptionLabel={(option) => option.label}
                                    disableClearable={false}
                                    renderInput={(params) => <TextField {...params}
                                        label={"Sales Rep"} variant="outlined" margin="dense" size="small" />
                                    }
                                />
                            </FormControl>

                        </div>
                    </div>


                    <div>
                        <div style={{ display: 'grid', gridTemplateColumns: '100% auto', gridGap: '10%' }}>
                            <div>
                                <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                                    <Autocomplete
                                        name='terms'
                                        key={`terms-key-list`}
                                        defaultValue={termsList.find(v => v.value == quoteObjectData.terms)}
                                        options={termsList}
                                        onChange={(e, event) => handleAction(e, 'terms', '', event)}
                                        getOptionLabel={(option) => option.label}

                                        disableClearable={false}
                                        renderInput={(params) => <TextField {...params}
                                            label={"Terms"} variant="outlined" margin="dense" size="small" />
                                        }
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div></div>

                    <div style={{ display: 'grid' }}>
                        <div>
                            <PopOver
                                id={'flatpicker-1'}
                                key={'flatpicker-1'}
                                name={'quoteDate'}
                                btnType={'date'}
                                buttonStyle={{ fontSize: '16px' }}
                                containerStyle={{ lineHeight: 1, marginBottom: '20px' }}
                                buttonLabel={quoteObject.header + ' Date'} //*
                                onChange={(date) => handleFlatPickrDateChange(date, "quoteDate")}
                                value={quoteDateObj}
                                innerLabelStyle={{ padding: "8 5 2 5", fontSize: '15px' }}
                                height={40}
                                options={datePickerOptions}
                                buttonOuterLabelStyle={{ backgroundColor: '#fafafa', lineHeight: '0.2', marginTop: '-2px', padding: '-1px', fontSize: 12, fontWeight: 500, color: '#717171' }}
                            />

                        </div>
                    </div>
                    <div style={{ display: 'grid' }}>
                        <div>
                            <PopOver
                                id={'flatpicker-2'}
                                key={'flatpicker-2'}
                                name={'quoteValid'}
                                btnType={'date'}
                                buttonStyle={{ fontSize: '16px' }}
                                containerStyle={{ lineHeight: 1, marginBottom: '20px' }}
                                //  buttonLabel={dueDateLabel}
                                buttonLabel={quoteObject.header == 'Invoice' ? 'Due Date' : 'Expiration Date'} //*
                                onChange={(date) => handleFlatPickrDateChange(date, "quoteValid")}
                                value={quoteValidDateObj}
                                innerLabelStyle={{ padding: "8 5 2 5", fontSize: '15px' }}
                                height={40}
                                options={datePickerOptions}
                                buttonOuterLabelStyle={{ backgroundColor: '#fafafa', lineHeight: '0.2', marginTop: '-2px', padding: '-1px', fontSize: 12, fontWeight: 500, color: '#717171' }}

                            />
                        </div>
                    </div>
                    <div></div>
                </div>
                <div className="row" style={{ paddingTop: '30px', width: '98%' }}>
                    <div style={{ border: '1px solid #b7b7b7', borderRadius: 5, background: '#fff' }}>
                        {generateProductItems()}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12" style={{ padding: '15px 0px', width: '98%' }}>
                        <FormControl style={{ width: '100%', backgroundColor: 'white' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                id='note-text'
                                name="note-text"
                                type='textarea'
                                multiline={true}
                                rows={3}
                                maxRows={3}
                                fullWidth={true}
                                textareastyle={{ marginTop: '10px', height: '90%' }}
                                value={quoteObjectData.notetext}
                                title={quoteObjectData.notetext}
                                onChange={(e, event) => handleAction(e, 'notetext', '', event)}
                                label={'Customer Note'}
                                autoComplete="new-password"
                            /></FormControl>
                    </div>
                </div>
            </div >
        );
    } else {
        return (
            <div style={{ width: '100%', height: pageHeight }}>
                <div className="asset-loaderh" style={{ paddingTop: top, paddingLeft: '45%' }}>
                    <div style={{ ...styles.assetLoaderContainer, height: 50, width: 50, padding: 7 }}>
                        <ShowCircularProgress size={30} style={{ marginTop: '3', marginLeft: '3' }} />
                    </div>
                </div>
            </div>
        );
    }

})
export default QuoteComponent;


var customerBillToDetailsFields = [
    { name: 'name', label: 'Name', field_type: 'text', is_lookup_field: true, lookup_object: 'contacts', lookup_field_name: 'full_name' },
    { name: 'address', label: 'Address', field_type: 'text', },
    { name: 'phone', label: 'Phone', field_type: 'text', is_phone_field: true },
    { name: 'email', label: 'Email', field_type: 'text', is_email_field: true }
];


var getDepFieldsList = (lookupObject) => {
    let list = null;
    try {
        if (lookupObject === constants.ACCOUNTS_OBJECT) {
            list = ['company'];
        } else if (lookupObject === constants.CONTACTS_OBJECT) {
            list = ['name', 'phone', 'email', 'address'];
        }
    } catch (error) {
        console.error("Error in 'quoteComponent.js -> getDepFieldsList()':" + error);
    }
    return list;
}

var mapFields = [
    { name: 'company', mapFieldsList: ['company'] },
    { name: 'name', mapFieldsList: ['full_name'] },
    { name: 'phone', mapFieldsList: ['phone'] },
    { name: 'email', mapFieldsList: ['email'] },
    { name: 'address', mapFieldsList: ["address1", "address2", "city", "state", "zip_code", "country"] }
];
