import React from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import { styles } from '../../../services/constants/styles';
import {
    getArrayParam,
    getBooleanParam,
    getObjectParam,
    getStringParam,
    isInvalidParam,
    isValidParam
} from '../../../services/helper/parameterVerifier';
import { getAppDialog, getAppDrawer } from '../../../services/actions/appContainerActions';
import { constants, EVENT_TYPE_KEYS } from '../../../services/constants/constants';
import * as HTTPClient from '../../../services/helper/httpClient';
import { endPoints } from '../../../services/constants/endPoints';
import { showCustomSnackBar } from '../../../services/actions/snackBarAction';
import { addNode, setMounted } from '../../../services/actions/automationCampaignActions';
import { alert } from './sfDialogs';
import {
    getActionByName,
    getChildNodeIds,
    getDaysBetween,
    getMaxId,
    getNodeByChildId,
    getNodeByParentId,
    getNodesByActionName,
    getParentByActionName,
    removeError,
    traverseNodeList,
    validateMessagesChildLink
} from '../../../services/helper/automationUtils';
import { hasAccessPermission } from '../../../services/helper/common';
import { getLocalizedStrings } from '../../../services/constants/MultiLingual';
import { addTab, TYPE_LIST_VIEW, updateActiveTabProps } from '../../../services/helper/sfTabManager';
import moment from 'moment';
import { dateTimeFormat, timeZone } from '../../../services/helper/utils';
import { setMarketingResults } from '../../../services/actions/marketingReportAction';
import * as sfDialogs from '../components/sfDialogs';
import PopOver from '../components/PopOver';
import ShowCircularProgress from './circularProgress';

const optionvalue = {
    Immediately: 'Immediately',
    Schedule: 'Schedule'
};

function mapStateToProps(state) {
    return {
        automationCampaign: state.automationCampaign,
        automationCampaignTemplate: state.automationCampaignTemplate,
        tab: state.tab,
        app: state.app,
        snackBar: state.snackBar,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAppDialog: (isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom) => {
            dispatch(getAppDialog(isOpenDialog, dialogType, dialogTitle, eventName, data, callFrom));
        },
        showCustomSnackBar: (message, bodyStyle, style) => {
            dispatch(showCustomSnackBar(message, bodyStyle, style));
        },
        addNode: (arrayNodes) => {
            dispatch(addNode(arrayNodes));
        },
        setMounted: (data) => {
            dispatch(setMounted(data));
        },
        getAppDrawer: (isOpen, actionDialogName, actionType, data, callFrom) => {
            dispatch(getAppDrawer(isOpen, actionDialogName, actionType, data, callFrom));
        },
        setMarketingResults: (marketingResults) => {
            dispatch(setMarketingResults(marketingResults));
        }
    }
}

class AutomationHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            campaignName: null,
            isFocusOnCampaignName: true,
            isShowErrorMsg: false,
            migrated: false,
            resultContent: null,
            campaignId: 0,
            startActionOpen: false,
            anchorStartActionEl: null,
            startDate: null,
            startMode: null,
            scheduled: 0,
            canceled: false,
            startModeImmediately: false,
            stop: false,
        }
    }

    handleStartActionOpen = (event) => {
        event.preventDefault();
        this.setState({ startActionOpen: true, anchorStartActionEl: event.currentTarget });
    }

    handleStartActionClose = (event) => {
        this.setState({
            startActionOpen: false
        });
    }

    handleStartActionChange = (value) => {
        this.setState({isLoading:true});
        if (value === 'Immediately') {
            this.state.startDate = null;
            this.state.startMode = 0;
            this.state.startModeImmediately = true;
            this.setState({ ...this.state });
            this.executeAutomation();
        } else if (value === 'Schedule') {
            this.setState({ startMode:1, startActionOpen: false });
            this.props.getAppDialog(true, constants.AUTOMATION_SCHEDULE_DIALOG, getLocalizedStrings().label.AUTOMATION_DESIGNER.SCHEDULE_DIALOG_TITLE, this.handleScheduleAutomation, null, null);
        }
    }

    handleScheduleAutomation = (obj) => {
        let date = moment(obj.date).format(constants.INPUT_DATE_TIME_FORMAT_24);
        this.setState({ startDate: date, scheduled: 1 },
            () => this.executeAutomation());
        this.props.getAppDialog(false, constants.AUTOMATION_SCHEDULE_DIALOG, null, null, null, null);
    }

    componentDidMount() {
        let campaignName = "";
        campaignName = this.props.automationCampaign.data.name;

        let templateName = this.props.automationCampaign.data.template_name;
        templateName = getStringParam(templateName);

        if (templateName === constants.BIRTHDAY_CAMPAIGN_TEMPLATE_NAME) {
            if (isInvalidParam(campaignName)) {
                campaignName = getLocalizedStrings().label.AUTOMATION_DESIGNER.BIRTHDAY_CAMPAIGN;
            }
        }
        this.setState({
            campaignName: campaignName
        })
        if (this.props.automationCampaign.data.hasOwnProperty('templateName') && this.props.automationCampaign.data.templateName !== "Birthday Campaign" && this.props.automationCampaign.data.id > 0) {
            this.checkAndMigrate();
        }

    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let campaignName = "";
        campaignName = this.props.automationCampaign.data.name;
        let templateName = this.props.automationCampaign.data.template_name;
        templateName = getStringParam(templateName);

        if (templateName === constants.BIRTHDAY_CAMPAIGN_TEMPLATE_NAME) {
            if (isInvalidParam(campaignName)) {
                campaignName = constants.BIRTHDAY_CAMPAIGN_NAME;
            }
        } else if (templateName === constants.PET_BIRTHDAY_CAMPAIGN_TEMPLATE_NAME) {
            if (isInvalidParam(campaignName)) {
                campaignName = constants.PET_BIRTHDAY_CAMPAIGN_NAME;
            }
        }
        this.setState({
            campaignName: campaignName
        })
    }

    changeCampaignName = (event, value) => {
        value = event.target.value
        if (isValidParam(value)) {
            this.props.automationCampaign.data.name = value;
            this.setState({
                campaignName: value
            })
        }
    }

    componentDidUpdate = () => {
        if (this.state.isFocusOnCampaignName) {
            let campaignField = document.getElementById('txtName');
            if (campaignField !== null && campaignField !== undefined) {
                setTimeout(function () { campaignField.focus(); }, 100);
                this.setState({
                    isFocusOnCampaignName: false,
                });
            }
        }
    }

    openDialog = (dialogData) => {
        this.props.getAppDialog(true, constants.ALERT_DIALOG, getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, null, dialogData, constants.AUTOMATION_DESIGNER_OBJECT);
    }

    addError = (objNode, msg, isError) => {
        objNode.is_error = isError;
        let error_msg = {
            error_msg: msg,
        }

        if (objNode.hasOwnProperty('arr_error')) {
            objNode.arr_error.push(error_msg);
        } else {
            let arr_error = [];
            arr_error.push(error_msg);
            objNode.arr_error = arr_error;
        }
    }

    validateData = (data) => {
        let returnParam = true;
        if (isValidParam(data)) {
            let dialogData = null;
            let nodes = data.nodes;
            if (isValidParam(nodes)) {
                let arrNodes = nodes.filter(function (el) {
                    return el.detailStatusFlg !== 3 && el.nodeStatusFlg !== 3;
                });
                if (arrNodes.length === 1 && arrNodes[0].actionName === constants.AUTOMATION_DESIGNER_START) {
                    dialogData = {
                        message: getLocalizedStrings().message.AUTOMATION_DESIGNER.BLANK_CANVAS,
                    }
                    this.openDialog(dialogData);
                    returnParam = false;
                }
            }
            let treeNodes = getChildNodeIds(nodes, 0, "", "");
            let arrTreeNodes;
            if (treeNodes !== null && treeNodes !== undefined && treeNodes !== "") {
                arrTreeNodes = treeNodes.toString().split(", ");
            }
            nodes.map((objNode, i) => {
                objNode = removeError(objNode);
                if (nodes[i].nodeStatusFlg !== 3) {
                    if (objNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL || objNode.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL) {
                        if (objNode.hasOwnProperty('details')) {
                            let objDetail = objNode.details[0];
                            if (objDetail !== null && !objDetail.hasOwnProperty('startDate')) {
                                if (objNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL) {
                                    let waitNode = getNodeByParentId(nodes, objNode.childId);
                                    waitNode = waitNode.filter(n => n.nodeStatusFlg !== 3);
                                    waitNode = waitNode[0];
                                    let firstMessageNode = getNodeByParentId(nodes, waitNode.childId);
                                    firstMessageNode = firstMessageNode.filter(n => n.nodeStatusFlg !== 3);
                                    firstMessageNode = firstMessageNode[0];
                                    if (firstMessageNode.hasOwnProperty('details') && isValidParam(firstMessageNode.details[0])) {
                                        if (isValidParam(firstMessageNode.details[0].hDate) && firstMessageNode.details[0].hDate !== null) {
                                            objDetail.hDate = firstMessageNode.details[0].hDate;
                                            objDetail.startDate = firstMessageNode.details[0].hDate;
                                        }
                                    }
                                } else if (objNode.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL) {
                                    objDetail.hDate = "";
                                    objDetail.startDate = "";
                                }
                            }
                            if (objNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL && objDetail && objDetail.hasOwnProperty('groups') && objDetail.groups === '' && objDetail.hasOwnProperty('campaign') && objDetail.campaign === '') {
                                this.addError(objNode, getLocalizedStrings().message.AUTOMATION_DESIGNER.EDIT_AND_COMPLETE_DETAILS + " " + objNode.actionLabel, true);
                                returnParam = false;
                            }
                        }
                    }

                    if (isValidParam(arrTreeNodes) && arrTreeNodes.length > 0) {
                        let arrNd = arrTreeNodes.filter(function (el) {
                            return el === objNode.childId;
                        });
                        if (arrNd.length <= 0) {
                            // Invalid Tree Structure.
                        }
                    }

                    /******************************************************************************/
                    /* Tasks & Decisions can't be a root node - except START			 		  */
                    /******************************************************************************/
                    if (objNode.actionName !== constants.AUTOMATION_DESIGNER_START && objNode.parentId === 0) {
                        let msg = '';
                        if (objNode.type === 1) {
                            msg = getLocalizedStrings().message.AUTOMATION_DESIGNER.CANNOT_START_WITH_DECISSION;
                        } else if (objNode.type === 2) {
                            msg = getLocalizedStrings().message.AUTOMATION_DESIGNER.CANNOT_START_WITH_ACTION;
                            if ((objNode.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL ||
                                objNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) &&
                                objNode.hasOwnProperty('is_new') && objNode.is_new) {
                                objNode.details = [];
                                this.props.addNode(this.props.automationCampaign.data);
                            }
                        } else if (objNode.type === 3) {
                            msg = objNode.actionLabel + " " + getLocalizedStrings().message.AUTOMATION_DESIGNER.NOT_ROOT_NODE;
                        }
                        if (msg && msg !== '') {
                            this.addError(objNode, msg, true);
                            returnParam = false;
                        }
                    }

                    /******************************************************************************/
                    /* Start with & Decision box can't be a leaf node							  */
                    /******************************************************************************/
                    if (objNode.type === 1 || objNode.type === 3 || objNode.actionName === constants.AUTOMATION_DESIGNER_WAIT) {
                        let arrChildNodes = getNodeByParentId(nodes, objNode.childId);
                        let actionLabel = objNode.actionLabel;
                        if (objNode.type === 1) {
                            actionLabel = actionLabel + "?";
                        }
                        if (arrChildNodes.length <= 0) {
                            this.addError(objNode, actionLabel + ' ' + getLocalizedStrings().message.AUTOMATION_DESIGNER.CANNOT_BE_LEAF_NODE, true);
                            returnParam = false;
                        } else {
                            var leafFlg = true;
                            for (var j = 0; j < arrChildNodes.length; j++) {
                                if (arrChildNodes[j].nodeStatusFlg !== 3) {
                                    leafFlg = false;
                                }
                            }
                            if (leafFlg) {
                                this.addError(objNode, actionLabel + ' ' + getLocalizedStrings().message.AUTOMATION_DESIGNER.CANNOT_BE_LEAF_NODE, true);
                                returnParam = false;
                            }
                        }

                        if (objNode.details !== null && objNode.details !== undefined && objNode.details.length > 0) {
                            if (objNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL && objNode.details[0] !== null &&
                                objNode.details[0] !== undefined && objNode.details[0].hasOwnProperty('startDate')) {
                                let currentStartDt = new Date(objNode.details[0].startDate);
                                let objTouch = getParentByActionName(nodes, objNode.parentId, constants.AUTOMATION_DESIGNER_SEND_MAIL);
                                if (objTouch !== null && objTouch !== undefined && objTouch.details[0] !== null &&
                                    objTouch.details[0] !== undefined && objTouch.details[0].hasOwnProperty('startDate')) {
                                    let parentStartDt = new Date(objTouch.details[0].startDate);
                                    let daysInterval = getDaysBetween(parentStartDt, currentStartDt);
                                    let parentResend = objTouch.details[0].resendAfter;

                                    if (daysInterval <= 0) {
                                        this.addError(objNode, getLocalizedStrings().message.AUTOMATION_DESIGNER.START_DATE_MUST_BE_GREATER, true);
                                        returnParam = false;
                                    } else {
                                        // This section used to calculate interval between parent message and current message using WAIT node & parent ADD_MESSAGE node.
                                        let interval = 0;
                                        let objParent = getParentByActionName(nodes, objNode.parentId, constants.AUTOMATION_DESIGNER_WAIT);
                                        if (objParent !== null && objParent !== undefined &&
                                            objParent.actionName === constants.AUTOMATION_DESIGNER_WAIT && objParent.details[0] !== null &&
                                            objParent.details[0] !== undefined && objParent.details[0].hasOwnProperty('interval')) {
                                            interval = objParent.details[0].interval / 24;
                                        }

                                        objParent = getParentByActionName(nodes, objNode.parentId, constants.AUTOMATION_DESIGNER_ADD_MESSAGE);
                                        if (objParent !== undefined && objParent !== null &&
                                            objParent.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE &&
                                            objParent.details[0] !== null && objParent.details[0] !== undefined &&
                                            objParent.details[0].hasOwnProperty('dbp') &&
                                            objParent.details[0].hasOwnProperty('interval_mode')) {
                                            if (objParent.details[0].interval_mode === "days") {
                                                interval = interval + parseInt(objParent.details[0].dbp);
                                            } else {
                                                let parentDaysInterval = parseInt(objParent.details[0].dbp) / 24;
                                                if (1 <= parentDaysInterval) {
                                                    interval = interval + parentDaysInterval;
                                                }
                                            }
                                        }
                                        if (daysInterval < interval) {
                                            this.addError(objNode, getLocalizedStrings().message.AUTOMATION_DESIGNER.CHECK_START_DATE_OR_WAIT_BEFORE_SAVE_TC, true);
                                            returnParam = false;
                                        }
                                    }

                                    if (parentResend > 0 && daysInterval <= parentResend) {//parentResend > 0 && interval <= parentResend
                                        this.addError(objNode, getLocalizedStrings().message.AUTOMATION_DESIGNER.CHECK_INTERVAL_WITH_PREVIOUS_TOUCH_CAMPAIGN, true);
                                        returnParam = false;
                                    }
                                }
                            } else if (objNode.actionName === constants.AUTOMATION_DESIGNER_WAIT &&
                                isValidParam(objNode.details[0]) && objNode.details[0].hasOwnProperty('interval')) {
                                // let skipValidation = false;
                                // if (objNode.parentId !== 0) {
                                //     let tempPaentNode = getNodeByChildId(nodes, objNode.parentId);
                                //     if (tempPaentNode.hasOwnProperty('actionName') && tempPaentNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL) {
                                //         skipValidation = true;
                                //     } else {
                                //         // Checking if Send Mail in Yes / No Chain and value of Wait
                                //         // If in yes chain Wait can be 0
                                //         // If in no chain immediate wait has to greater than 0 and in any other case it can be 0
                                //         let arrayNodes = this.props.automationCampaign.data.nodes;
                                //         let immediatePreviousId = objNode.parentId;
                                //         let immediateNextId = objNode.childId;
                                //         let immediateParentNodeDetails = getNodeByChildId(arrayNodes, immediatePreviousId);
                                //         let immediateNextNodeDetails = getNodeByParentId(arrayNodes, immediateNextId);
                                //         immediateNextNodeDetails = immediateNextNodeDetails.filter(n => n.actionName === 'AUTO_MAIL');
                                //         if (immediateNextNodeDetails.length > 0) {
                                //             skipValidation = true;
                                //             if (immediateParentNodeDetails.hasOwnProperty('actionName') &&
                                //                 immediateParentNodeDetails.actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK && objNode.decisionFlg === 2) {
                                //                 skipValidation = false;
                                //             }
                                //         }
                                //     }
                                // }
                                let skipValidation = false;
                                let arrayNodes = this.props.automationCampaign.data.nodes;
                                let immediateNextId = objNode.childId;
                                let immediateNextNodeDetails = null;
                                immediateNextNodeDetails = getNodeByParentId(arrayNodes, immediateNextId);
                                if (isValidParam(immediateNextNodeDetails)) {
                                    immediateNextNodeDetails = immediateNextNodeDetails.filter(n => n.nodeStatusFlg !== 3);
                                    immediateNextNodeDetails = immediateNextNodeDetails[0];
                                }
                                if (objNode.decisionFlg === 0 || objNode.decisionFlg === 1) {
                                    skipValidation = true;
                                } else if (objNode.decisionFlg === 2) {
                                    let immediatePreviousId = objNode.parentId;
                                    let immediateParentNodeDetails = getNodeByChildId(arrayNodes, immediatePreviousId);
                                    if (isValidParam(immediateParentNodeDetails) && immediateParentNodeDetails.type === 1) {
                                        skipValidation = false;
                                    } else {
                                        skipValidation = true;
                                    }
                                }

                                if (objNode.parentId !== 0) {
                                    let tempPaentNode = getNodeByChildId(this.props.automationCampaign.data.nodes, objNode.parentId);
                                    if (tempPaentNode.hasOwnProperty('actionName') && tempPaentNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL) {
                                        skipValidation = true;
                                    } else if (isValidParam(immediateNextNodeDetails) && immediateNextNodeDetails.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
                                        skipValidation = false;
                                    }
                                }
                                if (parseFloat(objNode.details[0].interval) < 1 && !skipValidation) {
                                    this.addError(objNode, getLocalizedStrings().message.AUTOMATION_DESIGNER.PROVIDE_WAIT_INTERVAL, true);
                                    returnParam = false;
                                }
                            }
                        }
                    }
                    /******************************************************************************/
                    /* All tasks needs to have the detail info. except - Start & Decisions		  */
                    /******************************************************************************/
                    if (objNode.actionName !== constants.AUTOMATION_DESIGNER_START && objNode.type !== constants.AUTOMATION_DESIGNER_ACTIONTYPE_ONE) {
                        if (objNode.details) {
                            if (objNode.details && objNode.details.length === 0) {
                                this.addError(objNode, getLocalizedStrings().message.AUTOMATION_DESIGNER.EDIT_AND_COMPLETE_DETAILS + " " + objNode.actionLabel, true);
                                returnParam = false;
                            }
                        } else {
                            this.addError(objNode, getLocalizedStrings().message.AUTOMATION_DESIGNER.EDIT_AND_COMPLETE_DETAILS + " " + objNode.actionLabel, true);
                            returnParam = false;
                        }
                    }

                    if (objNode.details !== undefined && objNode.details !== null && objNode.details.length > 0) {
                        /*if ((objNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL || objNode.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL) &&
                            objNode.details[0] !== null && objNode.details[0] !== undefined &&
                            objNode.details[0].hasOwnProperty('startDate')) {
                            if (objNode.details[0].startDate === null || objNode.details[0].startDate === undefined ||
                                objNode.details[0].startDate === "") {
                                this.addError(objNode, getLocalizedStrings().message.AUTOMATION_DESIGNER.PROVIDE_START_DATE, true);
                                returnParam = false;
                            }
                        }*/

                        if (objNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
                            let arrParentNodes = getNodeByChildId(nodes, objNode.parentId);
                            let detailsArr = objNode.details;
                            if (arrParentNodes !== null && arrParentNodes.parentId > 0) {
                                if (arrParentNodes.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL &&
                                    detailsArr !== null && detailsArr.length > 0) {
                                    detailsArr[0].sequence = 1;
                                }

                                if (isValidParam(arrParentNodes) && arrParentNodes.hasOwnProperty('actionName') &&
                                    isValidParam(arrParentNodes.actionName) &&
                                    arrParentNodes.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE &&
                                    isValidParam(detailsArr) && detailsArr.length && isValidParam(detailsArr[0])) {
                                    if (detailsArr[0].hasOwnProperty("dbp") && detailsArr[0].dbp === 0) {
                                        this.addError(objNode, getLocalizedStrings().message.AUTOMATION_DESIGNER.INTERVAL_EXPEXT_ZERO, true);
                                        objNode.details = [];
                                        this.props.addNode(this.props.automationCampaign.data);
                                        returnParam = false;
                                    } else if (detailsArr[0].hasOwnProperty("interval_mode") &&
                                        detailsArr[0].interval_mode === "hours" && detailsArr[0].dbp < 1) {
                                        this.addError(objNode, getLocalizedStrings().message.AUTOMATION_DESIGNER.SELECT_ONE_HOUR, true);
                                        returnParam = false;
                                    }
                                }
                            }
                            let isValid = validateMessagesChildLink(nodes, objNode.childId);
                            if (!isValid) {
                                this.addError(objNode, getLocalizedStrings().message.AUTOMATION_DESIGNER.EMPTY_LINK_NODE, true);
                                returnParam = false;
                            }
                        }
                        
                        if (objNode.actionName === constants.AUTOMATION_DESIGNER_ADD_SMS){
                            if (isValidParam(objNode.details) && isValidParam(objNode.details[0])) {
                                // Check for the presence of required properties
                                if (!objNode.details[0].hasOwnProperty('from_number') && !objNode.details[0].hasOwnProperty('message') && !objNode.details[0].hasOwnProperty('t_end_time') && !objNode.details[0].hasOwnProperty('t_start_time')) {
                                    this.addError(objNode, getLocalizedStrings().message.AUTOMATION_DESIGNER.EDIT_AND_COMPLETE_DETAILS + " " + objNode.actionLabel, true);
                                    returnParam = false;
                                }
                        }
                        }
                    }
                }
            });
        } else {
            returnParam = false;
        }
        return returnParam;
    }

    generateNodeObject = (objActions, sourceNode) => {
        let arrayNodes = this.props.automationCampaign.data.nodes;
        let maxId = 0;
        maxId = getMaxId(arrayNodes);
        let childId = 0;
        childId = maxId + 1;
        let processId = 0;
        if (objActions.actionName === constants.AUTOMATION_DESIGNER_SCORE_REACHED) {
            processId = "";
        }
        // else if (parentNode.actionName === constants.AUTOMATION_DESIGNER_LINK_CLICK) {
        //     processId = "";
        // }
        let nodeObject = {
            id: 0,
            parentId: 0,
            childId: childId,
            actionId: objActions.actionId,
            actionName: objActions.actionName,
            actionLabel: objActions.actionLabel,
            nodeStatusFlg: 1,
            detailStatusFlg: 1,
            status: "",
            processId: processId,
            secProcessId: 0,
            type: objActions.actionType,
            decisionFlg: 0,
            posX: sourceNode.posX,
            posY: sourceNode.posY + 100,
            details: [],
            referenceId: 0
        }
        return nodeObject;
    }

    checkAndMigrate = () => {
        let data = this.props.automationCampaign.data;
        let migrated = this.props.automationCampaign.data.isNew;
        if (!migrated && data.hasOwnProperty('status') && data.status !== 'Running') {
            let msgMigration = getLocalizedStrings().message.AUTOMATION_DESIGNER.MIGRATION;
            // let msgMigrationNote = getLocalizedStrings().message.AUTOMATION_DESIGNER.MIGRATION_NOTE;
            // let msg = <div><div>{msgMigration}</div><br/><div>{msgMigrationNote}</div></div>;
            let promise = new Promise((resolve, reject) => {
                resolve(alert(getLocalizedStrings().message.AUTOMATION_DESIGNER.MIGRATION_DIALOG_TITLE, msgMigration));
            });
            promise.then((isConfirmed) => {
                this.migrate();
            });
        }
    }

    // migrate = () => {
    //     let nodes = this.props.automationCampaign.data.nodes;
    //     let rootNote = getNodeByParentId(nodes, 0);
    //     rootNote = rootNote.filter(n => n.nodeStatusFlg !== 3 )
    //     rootNote = rootNote[0];
    //     let nextNote = getNodeByParentId(nodes, rootNote.childId);
    //     nextNote = nextNote.filter(n => n.nodeStatusFlg !== 3 );
    //     nextNote = nextNote[0];

    //     let isTouchCampaign = false;
    //     while (isValidParam(nextNote)) {
    //         if (nextNote.hasOwnProperty('actionName') && nextNote.actionName ===  constants.AUTOMATION_DESIGNER_SEND_MAIL ) {
    //             isTouchCampaign = true;
    //         } else if (isTouchCampaign && nextNote.hasOwnProperty('actionName') && nextNote.actionName ===  constants.AUTOMATION_DESIGNER_ADD_MESSAGE ) {
    //             let sourceNode = getNodeByChildId(nodes, nextNote.parentId);
    //             let objWaitAction = getActionByName(this.props.automationCampaign.data.actions, constants.AUTOMATION_DESIGNER_WAIT);
    //             let newNodeObject = this.generateNodeObject(objWaitAction, sourceNode);
    //             let interval = 120;
    //             if (nextNote.hasOwnProperty('details')) {
    //                 let detailsArray = nextNote.details[0];
    //                 if (isValidParam(detailsArray) && detailsArray.hasOwnProperty('dbp') ) {
    //                     interval = detailsArray.dbp * 24;
    //                 }
    //             }
    //             newNodeObject.details[0] = {
    //                 interval: interval,
    //             }
    //             newNodeObject.parentId = sourceNode.childId;
    //             newNodeObject.decisionFlg = 0;
    //             nextNote.parentId = newNodeObject.childId;
    //             nodes.push(newNodeObject);
    //             this.props.addNode(this.props.automationCampaign.data);
    //         }
    //         nextNote = getNodeByParentId(nodes, nextNote.childId);
    //         nextNote = nextNote.filter(n => n.nodeStatusFlg !== 3 );
    //         nextNote = getArrayParam(nextNote);
    //         if(isValidParam(nextNote[0])) {
    //             nextNote = nextNote[0];
    //         } else {
    //             nextNote = null;
    //         }
    //     }
    //     this.props.automationCampaign.isNew = 1;
    //     this.state.migrated = true;
    //     this.saveAutomation();
    // }

    migrate = () => {
        let nodes = this.props.automationCampaign.data.nodes;
        let rootNode = getNodeByParentId(nodes, 0);
        rootNode = rootNode.filter(n => n.nodeStatusFlg !== 3)
        rootNode = rootNode[0];
        let nextNode = getNodeByParentId(nodes, rootNode.childId);
        nextNode = nextNode.filter(n => n.nodeStatusFlg !== 3);
        nextNode = nextNode[0];

        while (isValidParam(nextNode)) {
            if (nextNode.hasOwnProperty('actionName') && (nextNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE || nextNode.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL)) {
                let sourceNode = getNodeByChildId(nodes, nextNode.parentId);
                let objWaitAction = getActionByName(this.props.automationCampaign.data.actions, constants.AUTOMATION_DESIGNER_WAIT);
                let newNodeObject = this.generateNodeObject(objWaitAction, sourceNode);
                let interval = 120;
                let interval_mode = 'days';
                if (nextNode.hasOwnProperty('details')) {
                    let detailsArray = nextNode.details[0];
                    if (nextNode.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE && isValidParam(detailsArray) && detailsArray.hasOwnProperty('dbp')) {
                        if (detailsArray.interval_mode === 'days') {
                            interval = detailsArray.dbp * 24;
                        } else if (detailsArray.interval_mode === 'hours') {
                            interval = detailsArray.dbp;
                            interval_mode = 'hours';
                        }
                    } else if (nextNode.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL && isValidParam(detailsArray) && detailsArray.hasOwnProperty('interval')) {
                        if (detailsArray.interval_mode === 'days') {
                            interval = detailsArray.interval * 24;
                        } else if (detailsArray.interval_mode === 'hours') {
                            interval = detailsArray.interval;
                            interval_mode = 'hours';
                        }
                    }
                }
                newNodeObject.details[0] = {
                    interval: interval,
                    interval_mode: interval_mode
                }
                newNodeObject.interval_mode = interval_mode;
                newNodeObject.parentId = sourceNode.childId;
                if (sourceNode.type === 1) {
                    newNodeObject.decisionFlg = nextNode.decisionFlg;
                } else {
                    newNodeObject.decisionFlg = sourceNode.decisionFlg;
                }

                nextNode.parentId = newNodeObject.childId;
                nodes.push(newNodeObject);
                this.props.addNode(this.props.automationCampaign.data);
            }
            nextNode = getNodeByParentId(nodes, nextNode.childId);
            nextNode = nextNode.filter(n => n.nodeStatusFlg !== 3);
            nextNode = getArrayParam(nextNode);
            if (isValidParam(nextNode[0])) {
                nextNode = nextNode[0];
            } else {
                nextNode = null;
            }
        }
        this.props.automationCampaign.isNew = 1;
        this.state.migrated = true;
        // this.saveAutomation();
    }

    checkAndUpdateStartDate = (data) => {
        // Adjust start date if user swip the first message
        if (data.hasOwnProperty('status') && data.status === constants.AUTOMATION_DESIGNER_STATUS_STOPPED) {
            let hasTouchCampaign = false;
            let touchNode = null;
            let node = getNodeByParentId(data.nodes, 0);
            if (isValidParam(node)) {
                node = node.filter(n => n.nodeStatusFlg !== 3);
                node = node[0];
            }
            do {
                if (node !== undefined && node.hasOwnProperty('actionName') && node.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL) {
                    hasTouchCampaign = true;
                    touchNode = node;
                }
                if (hasTouchCampaign && touchNode !== null) {
                    hasTouchCampaign = false;
                    touchNode = null;
                    node = getNodeByParentId(data.nodes, node.childId);
                    node = node.filter(n => n.nodeStatusFlg !== 3);
                    let cid = node[0].childId;
                    node = getNodeByParentId(data.nodes, cid);
                    node = node.filter(n => n.nodeStatusFlg !== 3);
                    if (isValidParam(node)) {
                        node = node[0];
                        if (node.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE) {
                            let firstTouchMsg = node;
                            do {
                                if (node.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE && node.hasOwnProperty('details') && isValidParam(node.details[0])) {
                                    if (node.details[0].hasOwnProperty('startDate') && node.details[0].startDate !== '' && node.details[0].startDate !== null) {
                                        firstTouchMsg.details[0].startDate = node.details[0].startDate;
                                        firstTouchMsg.details[0].hDate = node.details[0].hDate;
                                        let firstWaitNode = getNodeByChildId(data.nodes, firstTouchMsg.parentId);
                                        firstTouchMsg.details[0].dbp = firstWaitNode.details[0].interval / 24;
                                        if (firstTouchMsg.childId !== node.childId) {
                                            delete node.details[0].startDate;
                                            delete node.details[0].hDate;
                                        }
                                        break;
                                    }
                                }
                                node = getNodeByParentId(data.nodes, node.childId);
                                if (isValidParam(node)) {
                                    node = node.filter(n => n.nodeStatusFlg !== 3);
                                    node = node[0];
                                }
                            } while (node);
                        }
                    }

                }
                if (isValidParam(node)) {
                    node = getNodeByParentId(data.nodes, node.childId);
                    if (isValidParam(node)) {
                        node = node.filter(n => n.nodeStatusFlg !== 3);
                        node = node[0];
                    }
                }
            } while (node);
        }
        // end
    }

    saveAutomation = (is_resume = false) => {
        this.setState({ startActionOpen: false, stop: true });
        let hasPermission = getBooleanParam(hasAccessPermission(constants.AUTOMATION_DESIGNER_OBJECT, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT));
        if (hasPermission) {
            let campaignName = this.state.campaignName;
            let jsPlumbInst = this.props.automationCampaign.jsPlumbInst;
            campaignName = campaignName.trim();
            let dialogData = null;
            if (isInvalidParam(campaignName)) {
                dialogData = {
                    message: getLocalizedStrings().message.AUTOMATION_DESIGNER.BLANK_CAMPAIGN_NAME,
                    fieldId: 'txtName'
                }
                this.openDialog(dialogData);
                return false;
            } else {
                let data = this.props.automationCampaign.data;
                this.checkAndUpdateStartDate(data);
                data.name = campaignName;
                let templateName = null;
                let isNew = 1;
                let autoTempName = getStringParam(data.template_name);
                if (autoTempName === constants.BIRTHDAY_CAMPAIGN_TEMPLATE_NAME) {
                    let arrNodes = data.nodes;
                    arrNodes = arrNodes.filter(function (el) {
                        return el.detailStatusFlg !== 3 && el.nodeStatusFlg !== 3;
                    });

                    let bdayNodes = null;
                    bdayNodes = arrNodes.filter(function (el) {
                        return el.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL;
                    });
                    bdayNodes = getArrayParam(bdayNodes)
                    bdayNodes.map((bdayNode, i) => {
                        if (isValidParam(bdayNode.details)) {
                            let bd = bdayNode.details[0];
                            if (isValidParam(bd) && bd.hasOwnProperty('name')) {
                                bd.name = campaignName;
                            }
                            bdayNode.detailStatusFlg = 2;
                        } else {
                            bdayNode.details = [];
                            let detailsArray = {
                                name: campaignName,
                                from_name: this.props.app.me.name,
                                sender_email: this.props.app.me.email,
                                t_status: 'New',
                                autoresponder_id: 0,
                                startDate: null,
                                hDate: null,
                                campaign_type: 0
                            }
                            bdayNode.detailStatusFlg = 2;
                            bdayNode.details.push(detailsArray);
                        }
                    });
                }
                console.log("validate data",this.validateData(data))
                if (this.validateData(data)) {
                    // this.props.setMounted(false);
                    let arrNodes = data.nodes;
                    arrNodes = arrNodes.filter(function (el) {
                        return el.detailStatusFlg !== 3 && el.nodeStatusFlg !== 3;
                    });

                    let TouchNodes = null;
                    TouchNodes = arrNodes.filter(function (el) {
                        return el.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL;
                    });
                    TouchNodes = getArrayParam(TouchNodes)
                    TouchNodes.map((TouchNode, i) => {
                        if (isValidParam(TouchNode.details)) {
                            let tc = TouchNode.details[0];
                            if (isValidParam(tc) && tc.hasOwnProperty('name')) {
                                tc.name = campaignName + ' Touch';
                            }
                            TouchNode.detailStatusFlg = 2;
                        }
                    });

                    if (data.isTemplate !== 1) {
                        if (arrNodes !== undefined && arrNodes !== null && arrNodes.length > 0) {
                            arrNodes = traverseNodeList(arrNodes);
                        }
                    }
                    if (data.isTemplate !== 1) {
                        let arrNode = arrNodes.filter(function (el) {
                            return el.actionName === constants.AUTOMATION_DESIGNER_WAIT;
                        });
                        if (isValidParam(arrNode) && arrNode.length > 0 && arrNode[0].hasOwnProperty("details") &&
                            arrNode[0].details.length > 0) {
                            if (arrNode[0].details[0].hasOwnProperty("interval")) {
                                arrNode[0].intervalWait = arrNode[0].details[0].interval;
                            }
                        }
                    }
                    let noteObject = getNodeByParentId(arrNodes, 1);
                    if (isValidParam(noteObject)) {
                        let actionName = noteObject[0].actionName;
                        let actionLabel = noteObject[0].actionLabel;
                        if (actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL) {
                            templateName = "Touch Campaign";
                        } else if (actionName === constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN) {
                            templateName = "SMS Drip Campaign";
                        } else if (actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL) {
                            templateName = "Birthday Campaign";
                            if (actionLabel === "Pet Birthday Greetings") {
                                templateName = "Pet Birthday Campaign";
                            }
                        } else if (actionName === constants.AUTOMATION_DESIGNER_WEB_FORM) {
                            templateName = "Web Form";
                        } else if (actionName === constants.AUTOMATION_DESIGNER_LANDING_PAGE) {
                            templateName = "Landing Page";
                        }
                    }

                    arrNodes = data.nodes.sort(function (a, b) { return a.referenceId - b.referenceId });
                    let mBuilderObject = {
                        id: data.id,
                        imgUrl: data.imgUrl,
                        isTemplate: data.isTemplate,
                        name: campaignName,
                        nodes: arrNodes,
                        status: data.status,
                        editorType: data.editorType,
                        templateName: templateName,
                        isNew: isNew,
                        is_resume: data.hasOwnProperty('is_resume') ? data.is_resume : false,
                        startMode: this.state.startMode !== null ? this.state.startMode : data.startMode,
                    }
                    if (this.state.scheduled === 1) {
                        mBuilderObject.scheduled = 1;

                    }

                    const promise = Promise.resolve(HTTPClient.post(endPoints.AUTOMATION_DESIGNER.SAVE_CAMPAIGN, mBuilderObject));
                    promise.then((response) => {
                        if (isValidParam(response)) {
                            if (response?.status === 0 && isValidParam(response?.data)) {
                                const { data } = response;
                                this.setState({
                                    isShowErrorMsg: false
                                });
                                data.newId = data.id;
                                if (this.state.migrated) {
                                    this.setState({
                                        migrated: false
                                    });
                                } else if (is_resume === true) {
                                    this.startResumeAutomation();
                                } else {
                                    if (this.state.scheduled === 1) {
                                        this.props.showCustomSnackBar(getLocalizedStrings().message.AUTOMATION_DESIGNER.CAMPAIGN_SCHEDULE_SUCCESSFULLY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                                        this.setState({ isLoading: false, scheduled: 0 });
                                    } else if (this.state.canceled === true) {
                                        this.props.showCustomSnackBar(getLocalizedStrings().message.AUTOMATION_DESIGNER.SCHEDULED_CAMPAIGN_CANCEL_SUCCESSFULLY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                                        this.setState({ isLoading: false, canceled: false });
                                    } else if (this.state.startModeImmediately === true) {
                                        this.props.showCustomSnackBar(getLocalizedStrings().message.AUTOMATION_DESIGNER.CAMPAIGN_START_IMMEDIATELY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                                        this.setState({ isLoading: false, startModeImmediately: false });
                                    } else {
                                        this.setState({ isLoading: false, stop: false });
                                        this.props.showCustomSnackBar(getLocalizedStrings().message.AUTOMATION_DESIGNER.CAMPAIGN_SAVED_SUCCESSFULLY, styles.snackbarBodyStyleSuccess, styles.snackBarStyleTop);
                                    }
                                }
                                const updateTabProps = { label: data.name, url: '/automation-designer/edit/' + data.id }
                                setTimeout(()=>{
                                    if(this.props.tab.tabs.length > 1){
                                        updateActiveTabProps(updateTabProps);
                                        this.props.addNode(data);
                                    }
                                }, 2000)
                            }
                        }
                    });
                } else {
                    this.setState({
                        isShowErrorMsg: true,
                    })
                    this.props.addNode(data);
                }
            }
        } else {
            let dialogData = { message: getLocalizedStrings().message.COMMON.ACCESS_DENIED };
            this.openDialog(dialogData);
        }
    }

    stopAutomation = () => {
        try {
            let data = this.props.automationCampaign.data;
            if (isValidParam(data)) {
                if (data.hasOwnProperty('status')) {
                    data.status = constants.AUTOMATION_DESIGNER_STATUS_STOPPED;
                }
                if (data.hasOwnProperty('nodes')) {
                    let nodes = data.nodes;
                    nodes.map((objNode, i) => {
                        if (objNode.detailStatusFlg !== 3 && (objNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL ||
                            objNode.actionName === constants.AUTOMATION_DESIGNER_WEB_FORM ||
                            objNode.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL ||
                            objNode.actionName === constants.AUTOMATION_DESIGNER_LANDING_PAGE ||
                            objNode.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL ||
                            objNode.actionName === constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN)) {
                            objNode.status = constants.AUTOMATION_DESIGNER_STATUS_STOPPED;
                            objNode.nodeStatusFlg = 2;
                            objNode.detailStatusFlg = 2;
                            if (objNode.hasOwnProperty('details')) {
                                let nodeDetails = objNode.details;
                                if (objNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL ||
                                    objNode.actionName === constants.AUTOMATION_DESIGNER_WEB_FORM ||
                                    objNode.actionName === constants.AUTOMATION_DESIGNER_LANDING_PAGE ||
                                    objNode.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL ||
                                    objNode.actionName === constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN) {
                                    if (nodeDetails.length > 0 &&
                                        nodeDetails[0].t_status === constants.AUTOMATION_DESIGNER_STATUS_RUNNING) {
                                        nodeDetails[0].t_status = constants.AUTOMATION_DESIGNER_STATUS_STOPPED;
                                    }
                                } else if (objNode.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                                    if (nodeDetails.length > 0) {
                                        nodeDetails[0].t_status = constants.AUTOMATION_DESIGNER_STATUS_STOPPED;
                                    }
                                }
                            }
                        }
                    });
                    this.saveAutomation();
                }
            }
            this.checkAndMigrate();
        } catch (error) {
            console.error("Error in 'AutomationHeader.js -> stopAutomation()':" + error);
        }
    }

    executeAutomation = () => {
        try {
            let data = this.props.automationCampaign.data;
            if (isValidParam(data)) {
                if (this.validateData(data)) {
                    if (data.hasOwnProperty('status')) {
                        data.status = constants.AUTOMATION_DESIGNER_STATUS_RUNNING;
                        data.is_resume = false;
                    }
                    if (data.hasOwnProperty('nodes')) {
                        let nodes = data.nodes;
                        let TouchNodes = null;
                        let templateName = getStringParam(data.template_name);
                        if (templateName === constants.BIRTHDAY_CAMPAIGN_TEMPLATE_NAME) {
                            TouchNodes = nodes.filter(function (el) {
                                return el.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL;
                            });
                        } else {
                            TouchNodes = nodes.filter(function (el) {
                                return el.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL;
                            });
                        }
                        TouchNodes = getArrayParam(TouchNodes)
                        TouchNodes.map((TouchNode, i) => {
                            if (isValidParam(TouchNode.details)) {
                                let tc = TouchNode.details[0];
                                if (isValidParam(tc)) {
                                    // const momentDate = moment(new Date());
                                    // tc.startDate = momentDate.format(dateTimeFormat[this.props.app.me.date_format]);
                                    // tc.hDate = momentDate.format('YYYY-MM-DD HH:MM');
                                    if (this.state.startDate !== null) {
                                        tc.startDate = this.state.startDate;
                                        tc.hDate = this.state.startDate;
                                    } else {
                                        tc.startDate = "";
                                        tc.hDate = "";
                                    }
                                }
                            }
                        });


                        let objChildNode = getNodeByParentId(nodes, 1);
                        if (isValidParam(objChildNode)) {
                            objChildNode.map((nodeObject, i) => { 
                                if (nodeObject.detailStatusFlg !== 3 && (nodeObject.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL ||
                                    nodeObject.actionName === constants.AUTOMATION_DESIGNER_WEB_FORM ||
                                    nodeObject.actionName === constants.AUTOMATION_DESIGNER_LANDING_PAGE ||
                                    nodeObject.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL ||
                                    nodeObject.actionName === constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN)) {
                                    nodeObject.status = constants.AUTOMATION_DESIGNER_STATUS_RUNNING;
                                    nodeObject.nodeStatusFlg = 2;
                                    nodeObject.detailStatusFlg = 2;
                                    if (nodeObject.hasOwnProperty('details')) {
                                        let nodeDetails = nodeObject['details'];
                                        if (nodeDetails.length > 0) {
                                            nodeDetails[0].t_status = constants.AUTOMATION_DESIGNER_STATUS_RUNNING;
                                        }
                                    }
                                }
                            });
                            this.saveAutomation();
                        }
                    }
                } else {
                    this.setState({
                        isShowErrorMsg: true,
                    })
                    this.props.addNode(data);
                }
            }
        } catch (error) {
            console.error("Error in 'AutomationHeader.js -> executeAutomation()':" + error);
        }
    }

    resumeAutomation = () => {
        // save the campaign before start resume 
        this.saveAutomation(true);
    }

    startResumeAutomation = () => {
        try {
            let data = this.props.automationCampaign.data;
            if (isValidParam(data)) {
                if (this.validateData(data)) {
                    if (data.hasOwnProperty('status')) {
                        data.status = constants.AUTOMATION_DESIGNER_STATUS_RUNNING;
                        data.is_resume = true;
                    }
                    if (data.hasOwnProperty('nodes')) {
                        let detailsArray = data.nodes;
                        detailsArray.map((objNode, i) => {
                            if (objNode.detailStatusFlg !== 3 && (objNode.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL ||
                                objNode.actionName === constants.AUTOMATION_DESIGNER_WEB_FORM ||
                                objNode.actionName === constants.AUTOMATION_DESIGNER_LANDING_PAGE ||
                                objNode.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL ||
                                objNode.actionName === constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN)) {
                                objNode.status = constants.AUTOMATION_DESIGNER_STATUS_RUNNING;
                                objNode.nodeStatusFlg = 2;
                                objNode.detailStatusFlg = 2;
                                if (objNode.hasOwnProperty('details')) {
                                    let nodeDetails = objNode['details'];
                                    if (nodeDetails.length > 0) {
                                        nodeDetails[0].t_status = constants.AUTOMATION_DESIGNER_STATUS_RUNNING;
                                    }
                                }
                            }

                            if (objNode.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                                objNode.nodeStatusFlg = 2;
                                objNode.detailStatusFlg = 2;
                                if (objNode.hasOwnProperty('details')) {
                                    let nodeDetails = objNode['details'];
                                    if (nodeDetails.length > 0) {
                                        nodeDetails[0].t_status = "New";
                                    }
                                }
                            }
                        });
                        this.saveAutomation();
                    }
                } else {
                    this.setState({
                        isShowErrorMsg: true,
                    })
                    this.props.addNode(data);
                }
            }
            this.checkAndMigrate();
        } catch (error) {
            console.error("Error in 'AutomationHeader.js -> resumeAutomation()':" + error);
        }
    }

    copyAutomation = () => {
        try {
            let hasPermission = getBooleanParam(hasAccessPermission(constants.AUTOMATION_DESIGNER_OBJECT, constants.SECURITY_LEVEL_TYPE_ACCESS, constants.ACCESS_TYPE_EDIT));
            if (hasPermission) {
                let data = this.props.automationCampaign.data;
                if (isValidParam(data)) {
                    if (data.hasOwnProperty('startMode')) {
                        data.startMode = 0;
                    }
                    if (data.hasOwnProperty('nodes')) {
                        let copyArray = data.nodes;
                        if (isValidParam(copyArray)) {
                            let copyStatus = 0; /** Considering copy function is  allowed by default **/
                            for (var i = 0; i < copyArray.length; i++) {
                                let nodeObject = copyArray[i];
                                if (isValidParam(nodeObject) && (nodeObject['detailStatusFlg'] !== 0 || nodeObject['nodeStatusFlg'] !== 0)) {
                                    copyStatus = 1;
                                    break;
                                }
                            }
                            if (copyStatus === 0) {
                                if (data.hasOwnProperty('status')) {
                                    data.status = constants.AUTOMATION_DESIGNER_STATUS_ACTIVE;
                                }
                                if (data.hasOwnProperty('id')) {
                                    data.id = 0;
                                }
                                if (data.hasOwnProperty('name')) {
                                    // data.name = constants.AUTOMATION_DESIGNER_CONSTANT_COPY_OF + " " + data.name;
                                    data.name = getLocalizedStrings().label.AUTOMATION_DESIGNER.COPY_OF + " " + data.name;
                                }
                                copyArray.map((copyObject, i) => {
                                    copyObject.id = 0;
                                    copyObject.processId = "";
                                    if (copyObject.actionName !== constants.AUTOMATION_DESIGNER_SCORE_REACHED) {
                                        copyObject.secProcessId = "";
                                    }
                                    copyObject.status = "";
                                    if (copyObject.hasOwnProperty('details')) {
                                        if (copyObject.details[0] !== null && copyObject.details[0] !== undefined) {
                                            copyObject.details[0].t_status = "";
                                        }
                                    }
                                    copyObject.nodeStatusFlg = 1;
                                    copyObject.detailStatusFlg = 1;
                                    if (copyObject.hasOwnProperty("details") && copyObject.details !== null &&
                                        copyObject.details !== undefined) {
                                        let detailsArray = copyObject.details;
                                        detailsArray.map((objDetail, i) => {
                                            if (objDetail.hasOwnProperty("t_status")) {
                                                objDetail.t_status = "";
                                            }
                                            if (copyObject.actionName === constants.AUTOMATION_DESIGNER_SEND_MAIL || copyObject.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL) {
                                                objDetail.autoresponder_id = 0;
                                                // objDetail.name = constants.AUTOMATION_DESIGNER_CONSTANT_COPY_OF + " " + objDetail.name;
                                                objDetail.name = getLocalizedStrings().label.AUTOMATION_DESIGNER.COPY_OF + " " + objDetail.name;
                                                objDetail.startDate = "";
                                            } else if (copyObject.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE || copyObject.actionName === constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE) {
                                                objDetail.message_id = 0;
                                                objDetail.template.template_id = 0;
                                                if (objDetail.hasOwnProperty("startDate")) {
                                                    objDetail.startDate = "";
                                                }
                                            } else if (copyObject.actionName === constants.AUTOMATION_DESIGNER_WEB_FORM) {
                                                copyObject.details = null;
                                                copyObject.details = [];
                                            } else if (copyObject.actionName === constants.AUTOMATION_DESIGNER_AUTO_MAIL) {
                                                objDetail.id = 0;
                                                objDetail.template.template_id = 0;
                                                objDetail.t_status = "New";
                                            } else if (copyObject.actionName === constants.AUTOMATION_DESIGNER_LANDING_PAGE) {
                                                copyObject.details = null;
                                                copyObject.details = [];
                                            } else {
                                                objDetail.id = 0;
                                            }
                                        });
                                    }
                                });
                                this.props.automationCampaignTemplate.data.templates = [];
                                this.props.automationCampaignTemplate.data.templates.push(data);
                                this.props.addNode(data);
                                let linkToUrl = "/" + constants.AUTOMATION_DESIGNER_OBJECT + "/" + "edit/0";
                                updateActiveTabProps({ url: linkToUrl, label: data.name });
                            } else {
                                alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.SAVE_DIAGRAM_BEFORE_COPY);
                            }
                        }
                    }
                }
            } else {
                let dialogData = { message: getLocalizedStrings().message.COMMON.ACCESS_DENIED };
                this.openDialog(dialogData);
            }
        } catch (error) {
            console.error("Error in 'AutomationHeader.js -> copyAutomation()':" + error);
        }
    }

    showReport = () => {
        try {
            let arrayNodes = getNodesByActionName(constants.AUTOMATION_DESIGNER_SEND_MAIL);
            let templateName = this.props.automationCampaign.data.templateName;
            let campaignName = this.props.automationCampaign.data.name;
            templateName = getStringParam(templateName);
            if (templateName === constants.BIRTHDAY_CAMPAIGN_TEMPLATE_NAME || templateName === constants.BIRTHDAY_CAMPAIGN_NAME) {
                arrayNodes = getNodesByActionName(constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL);
            } else if (templateName === constants.SMS_DRIP_CAMPAIGN) {
                arrayNodes = getNodesByActionName(constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN);
            }
            if (isValidParam(arrayNodes) && arrayNodes.length > 0) {
                let nodeObject = arrayNodes[0];
                let nodeDetails = getObjectParam(nodeObject['details'][0]);
                let id = 0;
                if (templateName === constants.SMS_DRIP_CAMPAIGN) {
                    id = nodeDetails['id'];
                } else  {
                    id = nodeDetails['autoresponder_id'];
                }
                let dialogData = {
                    campaignId: id,
                }
                let dialogName = nodeDetails['name'];
                if(templateName === constants.SMS_DRIP_CAMPAIGN){
                    dialogName = campaignName
                }

                if (templateName === constants.BIRTHDAY_CAMPAIGN_TEMPLATE_NAME) {
                    dialogData.templateName = 'Birthday Campaign';
                }

                if (templateName === constants.BIRTHDAY_CAMPAIGN_TEMPLATE_NAME || templateName === constants.BIRTHDAY_CAMPAIGN_NAME) {
                    this.showBirthdayReport(nodeObject);
                } else if (templateName === constants.SMS_DRIP_CAMPAIGN) {
                    this.props.getAppDrawer(true, dialogName, constants.SMS_CAMPAIGN_REPORT, dialogData, constants.AUTOMATION_DESIGNER);
                } else {
                    this.props.getAppDrawer(true, dialogName, constants.AUTO_RESPONDER_REPORT, dialogData, constants.AUTOMATION_DESIGNER);
                }
            }
        } catch (error) {
            console.error("Error in 'AutomationHeader.js -> showReport()':" + error);
        }
    }

    showBirthdayReport = (node) => {
        let campaignId = node.details[0].autoresponder_id;
        let statId = 0;
        if (campaignId > 0) {
            this.state.resultContent = null;
            this.setState(this.state);
            let params = {
                campaign_id: campaignId,
                report_type: constants.BROADCAST_REPORT,
            }
            var promise = Promise.resolve(HTTPClient.get(endPoints.BROADCAST_CAMPAIGNS.RESULTS, params));

            promise.then((response) => {
                if (Array.isArray(response.marketing_results) && response.marketing_results.length > 0) {
                    this.state.resultContent = response;
                    this.state.campaignId = campaignId;
                    statId = response.marketing_results[0].id;
                    this.setState(this.state);
                    this.drilldownBCampaignReport(statId, 'Sent', constants.BIRTHDAY_REPORT_TYPE);
                } else {
                    this.state.resultContent = '';
                    this.setState(this.state);
                    sfDialogs.alert(getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, getLocalizedStrings().message.AUTOMATION_DESIGNER.BIRTHDAY_CAMPAIGN_NO_REPORT, null);
                }
            });
        }
    }

    drilldownBCampaignReport = (statsId, eventType, reportType) => {
        try {
            let params = {
                name: 'All records',
                tableId: 9,
            }

            var promise = Promise.resolve(HTTPClient.get(endPoints.REPORTS.QUERY_DETAIL, params));
            promise.then((response) => {
                if (isValidParam(response)) {
                    let params = response;
                    let label = "";
                    if (eventType === 'click') {
                        let reportName = this.state.gblStatIdSubArr.length > 0 ? this.state.gblStatIdSubArr[statsId] : null;
                        let eventTypeText = getLocalizedStrings().label.CAMPAIGNS.CLICK_URL;
                        label = eventTypeText + " " + reportName;
                    } else {
                        let eventTypeText = eventType.charAt(0).toUpperCase() + eventType.slice(1);
                        if (reportType === constants.BROADCAST_REPORT_TYPE) {
                            label = eventTypeText + " " + getLocalizedStrings().label.BROADCAST_CAMPAIGNS.EMAIL_CAMPAIGN_REPORT;
                        } else if (reportType === constants.AUTO_RESPONDER_REPORT_TYPE) {
                            let eventTypeTextUpper = eventTypeText.toUpperCase();
                            eventTypeText = getLocalizedStrings().label.CAMPAIGNS.hasOwnProperty(eventTypeTextUpper) ? getLocalizedStrings().label.CAMPAIGNS[eventTypeTextUpper] : eventTypeText;
                            label = eventTypeText + " " + getLocalizedStrings().label.AUTOMATION_DESIGNER.TOUCH_CAMPAIGN_REPORT;
                        } else if (reportType === constants.AB_REPORT_TYPE) {
                            label = eventTypeText + " " + getLocalizedStrings().label.AB_CAMPAIGNS.AB_CAMPAIGN_REPORT;
                        } else if (reportType === constants.BIRTHDAY_REPORT_TYPE) {
                            label = eventTypeText + " " + getLocalizedStrings().label.AUTOMATION_DESIGNER.BIRTHDAY_CAMPAIGN_REPORT;
                        }
                    }
                    let url = "/" + constants.CONTACTS_OBJECT + "/" + constants.BIRTHDAY_REPORT_TYPE.toLocaleLowerCase();
                    this.openTab(label, constants.CONTACTS_OBJECT, response.query_id, "fa fa-user", url, statsId, eventType);
                }
            });
        } catch (error) {
            console.error("Error in 'AutomationHeader.js -> drilldownBCampaignReport()':" + error);
        }
    }

    openTab = (label, component, queryId, imgName, url, statsId, eventType) => {
        let filter = {
            id: queryId,
            name: '',
            type: constants.TYPE_AUTORESPONDER_REPORT,
        }
        let tabType = TYPE_LIST_VIEW;
        let reportFieldValue = eventType;
        if (eventType === 'click') {
            // tabType = TYPE_LINK_CLICK_PAGE;
            reportFieldValue = 'Clicked';
        }
        let campaignName = null;
        let marketingResults = this.state.resultContent.marketing_results;
        let reportObj = marketingResults.filter(function (el) {
            return el.id === statsId;
        });
        campaignName = reportObj[0].campaign_name;
        let tab = {
            label: label,
            object: component,
            type: tabType,
            imgName: imgName,
            info: {
                filter: filter,
                input_param: {
                    stat_id: statsId,
                    event_type: eventType,
                },
                isReport: true,
                reportType: constants.BIRTHDAY_REPORT_TYPE,
                // reportType: this.state.reportType,
                event_type_key: EVENT_TYPE_KEYS[eventType],
                campaignId: this.state.campaignId,
                reportFieldValue: reportFieldValue,
                reportLabel: campaignName,
                recordCount: reportObj[0][EVENT_TYPE_KEYS[eventType]],
            },
            url: url,
            isActive: true
        };
        this.props.setMarketingResults(marketingResults);
        addTab(tab, true);
    }

    testAutomation = () => {
        try {
            let arrayNodes = getNodesByActionName(constants.AUTOMATION_DESIGNER_SEND_MAIL);
            let templateName = this.props.automationCampaign.data.templateName;
            templateName = getStringParam(templateName);
            if (templateName === constants.BIRTHDAY_CAMPAIGN_NAME) {
                arrayNodes = getNodesByActionName(constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL);
            }else if(templateName === constants.SMS_DRIP_CAMPAIGN){
                arrayNodes = getNodesByActionName(constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN);
            }
            arrayNodes = arrayNodes.filter(function (el) {
                return (el.detailStatusFlg !== 3 && el.nodeStatusFlg !== 3);
            });
            if (isValidParam(arrayNodes) && arrayNodes.length > 0) {
                let msgNodes = getNodesByActionName(constants.AUTOMATION_DESIGNER_ADD_MESSAGE);
                if (templateName === constants.BIRTHDAY_CAMPAIGN_NAME) {
                    msgNodes = getNodesByActionName(constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE);
                }else if(templateName === constants.SMS_DRIP_CAMPAIGN){
                    msgNodes = getNodesByActionName(constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN);
                }
                let isOpenTestMailDialog = false;
                if (isValidParam(msgNodes) && msgNodes.length > 0) {
                    msgNodes = msgNodes.filter(function (el) {
                        return el.id === 0;
                    });
                    if (msgNodes.length > 0) {
                        isOpenTestMailDialog = false;
                    } else {
                        isOpenTestMailDialog = true;
                    }

                    if (isOpenTestMailDialog) {

                        if (templateName === constants.BIRTHDAY_CAMPAIGN_TEMPLATE_NAME) {
                            let detailMailObj = arrayNodes[0].details[0];
                            msgNodes = getNodesByActionName(constants.AUTOMATION_DESIGNER_BIRTHDAY_MESSAGE);
                            let detailMsgObj = msgNodes[0].details[0];
                            let templateObj = detailMsgObj.template;
                            let params = {};
                            let senderEmail = detailMailObj.sender_email;
                            if (senderEmail === '') {
                                senderEmail = this.props.app.me.email;
                            }
                            params.email = '';
                            params.frommail = senderEmail;
                            params.fromname = detailMailObj.from_name;
                            params.subject = templateObj.subject;
                            params.templateBody = templateObj.emailBody;
                            if (detailMsgObj['view_as_web'] === 'Yes') {
                                params.isVawp = '1';
                            } else {
                                params.isVawp = '0';
                            }
                            params.testMailType = 'marketing';
                            this.props.getAppDialog(true, constants.TEST_MAIL_DIALOG, null, null, params, null);
                        } else if (templateName === constants.SMS_DRIP_CAMPAIGN) {
                            let params = {};
                            params.id = arrayNodes[0].details[0].id;
                            params.sms_type = "campaignSMS"
                            this.props.getAppDialog(true, constants.SMS_MESSAGE_DIALOG, null, null, params, null);
                        } else {
                            this.props.getAppDialog(true, constants.AUTOMATION_TEST_MAIL_DIALOG, null, null, arrayNodes, null);
                        }
                    } else {
                        let dialogData = {
                            message: getLocalizedStrings().message.AUTOMATION_DESIGNER.SAVE_AUTOMATION_FOR_TEST,
                        }
                        this.props.getAppDialog(true, constants.ALERT_DIALOG, getLocalizedStrings().message.COMMON.ALERT_DIALOG_TITLE, null, dialogData, constants.AUTOMATION_DESIGNER_OBJECT);
                    }
                }
            }
        } catch (error) {
            console.error("Error in 'AutomationHeader.js -> testAutomation()':" + error);
        }
    }

    cancelScheduleAutomation = (event) => {
        let msg = getLocalizedStrings().message.AUTOMATION_DESIGNER.CONFIRM_CANCEL_SCHEDULE;
        sfDialogs.confirm(getLocalizedStrings().message.COMMON.CONFIRM_DIALOG_TITLE, msg, this.cancelScheduleAutomationOnConfirm.bind(event), null);

    }
    cancelScheduleAutomationOnConfirm = (event) => {
        let allNodes = this.props.automationCampaign.data.nodes;
        let firstAddmessageNode = allNodes.filter(node => {
            return node.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE && node.details[0].hasOwnProperty('startDate') && isValidParam(node.details[0].startDate);
        });

        if (isValidParam(firstAddmessageNode[0])) {
            firstAddmessageNode = firstAddmessageNode[0];
            let message_id = null;
            if (firstAddmessageNode.hasOwnProperty('details') && isValidParam(firstAddmessageNode.details[0]) && firstAddmessageNode.details[0].hasOwnProperty('message_id')) {
                message_id = firstAddmessageNode.details[0].message_id;
            }
            if (isValidParam(message_id)) {
                let url = endPoints.AUTOMATION_DESIGNER.CAN_MSG_BE_DELETED;
                let params = {
                    messageId: message_id,
                }
                let promise = Promise.resolve(HTTPClient.get(url, params));
                promise.then((response) => {
                    if (response.can_msg_be_deleted === true) {
                        this.state.startActionOpen = false;
                        this.state.startMode = 0;
                        this.setState({ ...this.state });
                        // change the status to Active
                        this.props.automationCampaign.data.status = 'Active';

                        // remove the startDate from the first message.
                        if (isValidParam(firstAddmessageNode)) {
                            firstAddmessageNode.details[0].startDate = '';
                            firstAddmessageNode.details[0].hDate = '';
                        }
                        this.setState({ canceled: true });
                        this.saveAutomation();

                    } else {
                        let dialogData = {
                            message: getLocalizedStrings().message.AUTOMATION_DESIGNER.RUNNING_CAMPAIGN_CANNOT_CANCEL,
                        }
                        this.openDialog(dialogData);
                        return false;
                    }
                });
            }
        }
    }
    getStartMenuList = () => {
        let startMenuList = {
            'Immediately': getLocalizedStrings().label.AUTOMATION_DESIGNER.START_IMMEDIATELY,
            'Schedule': getLocalizedStrings().label.AUTOMATION_DESIGNER.START_SCHEDULE
        };
        return startMenuList;
    }

    render() {
        let campaignStatus = this.props.automationCampaign.data.status;
        let campaignId = this.props.automationCampaign.data.id;
        let email_credit_balance = this.props.automationCampaign.data.email_credit_balance;
        let isDisabled = false;
        if (campaignStatus === constants.AUTOMATION_DESIGNER_STATUS_RUNNING) {
            isDisabled = true;
        }
        let leftColWidth = '500px';
        let rightColWidth = 'calc(100% - 500px)';

        let copyBtnPaddingLeft = '1px';
        if (campaignStatus === constants.AUTOMATION_DESIGNER_STATUS_RUNNING) {
            copyBtnPaddingLeft = '0px';
        } else if (campaignStatus === constants.AUTOMATION_DESIGNER_STATUS_ACTIVE) {
            campaignStatus = getLocalizedStrings().label.COMMON.ACTIVE;
            copyBtnPaddingLeft = '7px';
        } else {
            copyBtnPaddingLeft = '1px';
        }

        let reportBtnPaddingLeft = '7px';
        if (campaignStatus === constants.AUTOMATION_DESIGNER_STATUS_RUNNING) {
            reportBtnPaddingLeft = '0px';
        } else {
            reportBtnPaddingLeft = '7px';
        }
        let isShowRepBtn = false;
        let arrayNodes = getNodesByActionName(constants.AUTOMATION_DESIGNER_SEND_MAIL);
        if(arrayNodes == null || arrayNodes.length == 0){
            arrayNodes = getNodesByActionName(constants.AUTOMATION_DESIGNER_SMS_CAMPAIGN);
        }
        let isShowTestButton = false;

        let templateName = this.props.automationCampaign.data.template_name;
        templateName = getStringParam(templateName);
        if (templateName === constants.BIRTHDAY_CAMPAIGN_TEMPLATE_NAME) {
            arrayNodes = getNodesByActionName(constants.AUTOMATION_DESIGNER_BIRTHDAY_MAIL);
        }

        if (isValidParam(arrayNodes) && arrayNodes.length > 0) {
            arrayNodes = arrayNodes.filter(function (el) {
                return el.detailStatusFlg !== 3 && el.nodeStatusFlg !== 3;
            });
            if (isValidParam(arrayNodes) && arrayNodes.length > 0 && campaignId > 0) {
                isShowTestButton = true;
            }
            if (arrayNodes.length === 1) {
                let nodeObject = getObjectParam(arrayNodes[0]);
                if (isValidParam(nodeObject) && nodeObject.hasOwnProperty('id') && nodeObject['id'] > 0) {
                    isShowRepBtn = true;
                } else {
                    isShowRepBtn = false;
                }
            } else if (arrayNodes.length > 1) {
                for (let i = 0; i < arrayNodes.length; i++) {
                    if (isValidParam(arrayNodes[i]) && arrayNodes[i].hasOwnProperty('id') && arrayNodes[i]['id'] > 0) {
                        isShowRepBtn = true;
                        break;
                    }
                }
            }
        }
        if (!isShowRepBtn) {
            copyBtnPaddingLeft = '7px';
        }
        if (!isShowRepBtn && campaignStatus === constants.AUTOMATION_DESIGNER_STATUS_RUNNING) {
            copyBtnPaddingLeft = '0px';
        }
        let hDate = null;
        let startDate = null;
        let scheduled = false;
        let template = this.props.automationCampaign.data.templateName;
        let startMode = this.props.automationCampaign.data.startMode;
        if (template === "Touch Campaign" && campaignStatus === constants.AUTOMATION_DESIGNER_STATUS_RUNNING) {
            let allNodes = this.props.automationCampaign.data.nodes;
            let firstAddmessageNode = allNodes.filter(node => {
                return node.actionName === constants.AUTOMATION_DESIGNER_ADD_MESSAGE && node.details[0].hasOwnProperty('startDate') && isValidParam(node.details[0].startDate);
            });
            if (isValidParam(firstAddmessageNode[0])) {
                startDate = firstAddmessageNode[0].details[0].startDate;
                hDate = moment.tz(firstAddmessageNode[0].details[0].hDate, timeZone['GMT']);
                hDate = hDate.tz(timeZone[this.props.app.me.timezone]).format(dateTimeFormat[this.props.app.me.date_format]);
                let currentTime = moment().tz(timeZone[this.props.app.me.timezone]);
                currentTime = moment.tz(currentTime, timeZone['GMT']);
                let scheduledDate = moment.tz(firstAddmessageNode[0].details[0].hDate, timeZone['GMT']);
                if (scheduledDate > currentTime) {
                    scheduled = true;
                } else {
                    hDate = null;
                }

            }

        }

        let menuList = [];
        let tempMenuList = this.getStartMenuList();
        Object.keys(optionvalue).map((itemKey) => {
            let data = {};
            data.label = tempMenuList[optionvalue[itemKey]];
            data.value = itemKey;
            menuList.push(data);
        });
        let sms_credit_balance = this.props.automationCampaign.data.sms_credit_balance

        console.log('222222222222222222222222222222', startMode, campaignStatus, constants.AUTOMATION_DESIGNER_STATUS_RUNNING, scheduled)
        return (
            <div>
                <div>
                    <div style={{ display: 'inline-block', float: '', fontSize: '12px', color: '#717171', paddingRight: '20px', paddingBottom: '10px' }}>
                       {this.props.automationCampaign.data.templateName === constants.SMS_DRIP_CAMPAIGN ?
                        <span>
                            {new Intl.NumberFormat().format(sms_credit_balance)} {getLocalizedStrings().message.AUTOMATION_DESIGNER.SMS_CREDITS_REMAINING}
                        </span>
                        :
                        <span>
                            {new Intl.NumberFormat().format(email_credit_balance)} {getLocalizedStrings().message.AUTOMATION_DESIGNER.CREDITS_REMAINING}
                        </span>
                       }
                    </div>
                    <div style={{ display: 'inline-block', float: 'right', paddingRight: '15px' }}>
                        {
                            (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                            <div style={{ display: 'inline-block', paddingLeft: '10px' }}>
                                <Button
                                    style={styles.listViewPrimaryButton}
                                    onClick={this.saveAutomation}
                                >{getLocalizedStrings().label.COMMON.SAVE}</Button>
                            </div>
                        }

                        {
                            (campaignStatus === constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                            <Button
                                onClick={this.stopAutomation}
                                style={styles.primaryButton}
                            >{getLocalizedStrings().label.AUTOMATION_DESIGNER.STOP}</Button>
                        }

                        {
                            (isShowRepBtn && (campaignStatus === constants.AUTOMATION_DESIGNER_STATUS_RUNNING ||
                                campaignStatus === constants.AUTOMATION_DESIGNER_STATUS_STOPPED)) &&
                            <div style={{ display: 'inline-block', paddingLeft: reportBtnPaddingLeft }}>
                                <Button
                                    onClick={this.showReport}
                                    style={styles.secondaryButton}
                                    startIcon={<i className="fa fa-bar-chart" />}
                                >{getLocalizedStrings().label.AUTOMATION_DESIGNER.REPORT}</Button>
                            </div>
                        }

                        {
                            (campaignId > 0) &&
                            <div style={{ display: 'inline-block', paddingLeft: copyBtnPaddingLeft }}>
                                <Button
                                    style={styles.secondaryButton}
                                    onClick={this.copyAutomation}
                                >{getLocalizedStrings().label.COMMON.COPY}</Button>
                            </div>
                        }

                        {
                            (isShowTestButton) &&
                            <div style={{ display: 'inline-block', paddingLeft: 0 }}>
                                <Button
                                    style={styles.secondaryButton}
                                    onClick={this.testAutomation}
                                >{getLocalizedStrings().label.AUTOMATION_DESIGNER.TEST}</Button>
                            </div>
                        }

                        <div style={{ display: 'inline-block' }}>
                            {
                                (campaignStatus === constants.AUTOMATION_DESIGNER_STATUS_ACTIVE && campaignId > 0 && this.props.automationCampaign.data.templateName !== "Touch Campaign") &&
                                <Button
                                    style={styles.secondaryButton}
                                    onClick={this.executeAutomation}
                                >{getLocalizedStrings().label.AUTOMATION_DESIGNER.START}</Button>
                            }
                            {
                                (campaignStatus === constants.AUTOMATION_DESIGNER_STATUS_ACTIVE && campaignId > 0 && this.props.automationCampaign.data.templateName === "Touch Campaign") &&
                                <div>
                                    <PopOver
                                        id={'automation-header-start'}
                                        key={'automation-header-start'}
                                        name={'automation-header-start'}
                                        buttonEndIcon={"keyboard_arrow_down"}
                                        buttonStyle={styles.secondaryButton}
                                        labelposition={"before"}
                                        buttonLabel={getLocalizedStrings().label.AUTOMATION_DESIGNER.START}
                                        options={menuList}
                                        onclickAction={this.handleStartActionChange}
                                        isShowIcon={false}
                                        //title={getLocalizedStrings().label.COMMON.MORE}
                                        anchorEl={this.state.anchorMoreActionEl}
                                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                                        targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                                    />
                                </div>
                            }
                            {(startMode === 1 && template === "Touch Campaign" && campaignStatus === constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                                <Button
                                    style={styles.secondaryButton}
                                    onClick={this.cancelScheduleAutomation}
                                >{getLocalizedStrings().label.COMMON.CANCEL}</Button>
                            }

                            {
                                (campaignStatus === constants.AUTOMATION_DESIGNER_STATUS_STOPPED) &&
                                <Button
                                    style={styles.secondaryButton}
                                    onClick={this.resumeAutomation}
                                >{getLocalizedStrings().label.AUTOMATION_DESIGNER.RESUME}</Button>
                            }
                        </div>
                    </div>
                </div>
                <div style={{ width: '100%', overflow: 'hidden' }}>
                    <div style={{ width: leftColWidth, marginRight: '15px', float: 'left', marginTop: '5px' }}>
                        <FormControl style={{ width: '100%' }} className="test" noValidate autoComplete="off">
                            <TextField
                                variant="outlined"
                                fullWidth={true}
                                label={getLocalizedStrings().label.AUTOMATION_DESIGNER.CAMPAIGN_NAME}
                                inputprops={{ maxlength: '128' }}
                                id="txtName"
                                disabled={isDisabled}
                                value={this.state.campaignName}
                                onChange={this.changeCampaignName}
                                autoComplete="new-password"
                                margin='dense'
                                size='small'
                                className={"sf-fields-bg"}
                            />
                        </FormControl>
                    </div>
                    <div style={{ display: 'inline-block', verticalAlign: 'top', paddingTop: '15px' }}>
                        {
                            (campaignStatus !== constants.AUTOMATION_DESIGNER_STATUS_RUNNING && this.state.isShowErrorMsg) &&
                            <span style={{ color: 'red' }}>
                                {getLocalizedStrings().message.AUTOMATION_DESIGNER.CANNOT_SAVE_CAMPAIGN}
                            </span>
                        }
                        {
                            (campaignStatus === constants.AUTOMATION_DESIGNER_STATUS_RUNNING) &&
                            <span style={{ color: 'red' }}>
                                {getLocalizedStrings().message.AUTOMATION_DESIGNER.STOP_CAMPAIGN}
                            </span>
                        }
                    </div>

                    {startMode === 1 && campaignStatus === constants.AUTOMATION_DESIGNER_STATUS_RUNNING && scheduled === true ?
                        <div style={{ display: 'inline-block', verticalAlign: 'top', paddingTop: '15px', float: 'right', paddingRight: '22', color: '#228B22', fontWeight: 'bold' }}>{getLocalizedStrings().label.AUTOMATION_DESIGNER.SCHEDULE_ON_TEXT} {startDate}</div>
                        : <div style={{ display: 'inline-block', verticalAlign: 'top', paddingTop: '15px', float: 'right', paddingRight: '22', color: '#228B22', fontWeight: 'bold' }}>
                            <div style={{ display: 'flex' }}>
                                <span>Status:  </span> {this.state.isLoading || !campaignStatus ? <img src={'/asset/images/cr-loading.gif'} alt="xx" width="15" height="15" className='dlimg-tick' style={{ margin: '4px 10px 3px' }} /> : 
                                    <span style={{ marginLeft: '5px' }}>{campaignStatus}</span> }
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AutomationHeader);